import React, { useEffect, useState } from 'react'
import './style.css'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setFilterParam, setProductId, setProductName } from '../../../reduxSlices/apiRequestTypeSlice';
import GetDemo from '../../commonComponents/GetDemo';



const SearchBasedProducts = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(4);


    const calculateProducts = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 2000 && screenWidth > 1000) {
            setItemsPerPage(4);
        } else if (screenWidth <= 1000 && screenWidth > 600) {
            setItemsPerPage(3);
        } else if (screenWidth <= 600) {
            setItemsPerPage(2);
        }
    }


    useEffect(() => {
        calculateProducts();
        window.addEventListener('resize', calculateProducts);

        return () => {
            window.removeEventListener('resize', calculateProducts)
        };
    }, [])

    const openModal = (name) => {
        dispatch(setProductName(name));
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    var displayedProducts = data.length > itemsPerPage ? data.slice(0, itemsPerPage) : data;

    const goToProductsPage = () => {
        dispatch(setFilterParam('all'));
        navigate('/products');
    }

    const openProductDetails = (product) => {
        navigate(`/details/${product.name}/${product.productId}`)
        dispatch(setProductId(product.productId))
    }


    return (
        <div className='searchBasedProducts'>
            <div className='searchBasedProductsTop'>
                <span className='searchBasedProductsTitle'>Related Products Based on your Search</span>
                <span className='searchBasedProductsRight' onClick={() => goToProductsPage()}>See All</span>
            </div>
            <div className='searchBasedProductsContent'>
                {
                    displayedProducts.map((product, index) => (
                        <div className='searchBasedProductsContainer' key={index}>
                            <img src={product.logo} className='searchBasedProductsLogo' alt='logo' onClick={() => openProductDetails(product)} />
                            <div className='searchBasedProductsMid'>
                                <span className='searchBasedProductsName' onClick={() => openProductDetails(product)}>{product.name}</span>
                                <span className='searchBasedProductsCompany'>By {product.brand}</span>
                                <div className='searchBasedProductsRating'>
                                    <div>
                                        <span>&#9733;</span><span>{product.rating}</span>
                                    </div>
                                    <div>Features</div>
                                </div>
                            </div>
                            <button className='searchBasedProductsBtn' onClick={() => openModal(product.name)}>Get Free Demo</button>
                            <GetDemo isOpen={isModalOpen} onClose={closeModal} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SearchBasedProducts

