import React, { useState } from 'react'
import './style.css'
import Rating from '@mui/material/Rating';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setProductId } from '../../../reduxSlices/apiRequestTypeSlice';


const ProductReview = ({ data }) => {
  const circulPercentage = 90;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let reviewLength = data.reviews.length;

  var feature = 0;
  var valueMoney = 0;
  var ease = 0;
  var support = 0;
  var recommendScore = 0;

  if (data.reviews.length > 0) {
    data.reviews.forEach((review) => {
      feature = feature + review.feature;
      valueMoney = valueMoney + review.valueForMoney;
      ease = ease + review.easeOfUse;
      support = support + review.customerSupport;
      recommendScore = recommendScore + review.recommendationScale;
    })
  } else {
    feature = 0;
    valueMoney = 0;
    ease = 0;
    support = 0;
    recommendScore = 0;
  }



  var overAllRating = (feature === 0 && valueMoney === 0 && ease === 0 && support === 0) ? 0 : ((feature + valueMoney + ease + support) / 4) / reviewLength;

  console.log(feature, valueMoney, ease, support);

  const addReview = () => {
    dispatch(setProductId(data.productId));
    navigate(`/addreview/${data.productId}`);
  }


  return (
    <div className='productReview'>
      <span className='reviewTitle'>{data.name} Reviews</span>
      <div className='productReviewContainerTop'>
        <div className='productReviewContainer1 container'>
          <div><CircularProgressbar value={recommendScore === 0 ? 0 : (recommendScore / reviewLength) * 10} text={`${recommendScore === 0 ? 0 : (recommendScore / reviewLength) * 10}%`} styles={{ text: { fill: 'var(--color)' } }} /></div>
          <span>Have recommended this product</span>
        </div>
        <div className='productReviewContainer2 container'>
          <div>
            <span>Overall Score</span>
            <span>{overAllRating}/5</span>
            <div>
              <Rating name="read-only" value={overAllRating} precision={0.5} readOnly />
            </div>
            <span>Based on {reviewLength} Reviews</span>
          </div>
          <div className='productReviewContainer2Child'>
            <div className='grandChild'>
              <div>
                <span>Features:</span>
                <span>{feature === 0 ? 0 : feature / reviewLength}/5</span>
              </div>
              <Rating name="read-only" value={feature / reviewLength} precision={0.5} readOnly />
            </div>
            <div className='grandChild'>
              <div>
                <span>Value for Money:</span>
                <span>{valueMoney === 0 ? 0 : valueMoney / reviewLength}/5</span>
              </div>
              <Rating name="read-only" value={valueMoney / reviewLength} precision={0.5} readOnly />
            </div>
            <div className='grandChild'>
              <div>
                <span>Ease of Use:</span>
                <span>{ease === 0 ? 0 : ease / reviewLength}/5</span>
              </div>
              <Rating name="read-only" value={ease / reviewLength} precision={0.5} readOnly />
            </div>
            <div className='grandChild'>
              <div>
                <span>Customer Support:</span>
                <span>{support === 0 ? 0 : support / reviewLength}/5</span>
              </div>
              <Rating name="read-only" value={support / reviewLength} precision={0.5} readOnly />
            </div>
          </div>
        </div>
        <div className='productReviewContainer3 container'>
          <span>We'd love to hear your feedback!
            <br />
            Review your software.
          </span>
          <button onClick={() => addReview()}>Add Review</button>
        </div>
      </div>
      {/* {
          reviews.map((review, index) => {
            <div>{review}</div>
          })
        } */}
    </div>
  )
}

export default ProductReview




