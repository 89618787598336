import React from 'react'
import './style.css'


const ProductDesc = ({ name, desc }) => {
    return (
        <div className='productDetailsDescSection' id='productSpec'>
            <span className='title'>{name}</span>
            <span className='desc'>{desc}</span>
        </div>
    )
}

export default ProductDesc

