import React from 'react'
import './style.css'

const ServiceTitle = () => {
  return (
    <div className='serviceTitle'>
      <div>Free Expert Consultation</div>
      <div>Get Instant License</div>
      <div>Easy and Secure checkout</div>
    </div>
  )
}

export default ServiceTitle

