import React from 'react'
import './style.css'
import { useSelector } from 'react-redux'
import Loader from '../../commonComponents/Loader';

const ProfileContent = () => {
    const user = useSelector((state) => state.user.user);

    return (
        <div className='profileContent'>
            {!user ? (
                <Loader />
            ) : (
                <div className="profileSection">
                    <div className="profileSectionLeft">
                        <div className="para">Name: {user.name}</div>
                        <div className="para">Email: {user.email}</div>
                        <div className="para">Contact: {user.contact}</div>
                    </div>
                    <div className="profileSectionRight"></div>
                </div>
            )}
        </div>
    )
}

export default ProfileContent

