import React from 'react'
import Footer from '../Footer'
import styled from 'styled-components'
import Navbar from '../Navbar'

const Contact = () => {
    return (
        <div>
            <Navbar />
            <StyledContent>
                <span className='mainTitle'>Contact us</span>
                <span className='title'>Last updated on Sep 10th 2023</span>

                <span className='title'></span>
                <li>Merchant Legal entity name: Digitalholder</li>
                <li>E-Mail ID: contact@digitalholder.in</li>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </StyledContent>
            <Footer />
        </div>
    )
}

export default Contact



const StyledContent = styled.div`

margin-top: 100px;
display: flex;
flex-direction: column;
margin-left: 5%;
margin-right: 5%;
margin-bottom: 50px;
gap: 1rem;


.text {
    font-size: 1rem;
}

.mainTitle {
    font-size: 1.5rem;
    font-weight: 800;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
}

`