import { Route, Routes } from 'react-router';
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import HomePage from './pages/HomePage';
import ProductDetail from './pages/ProductDetail';
import ProductPage from './pages/ProductPage';
import AdminPage from './pages/AdminPage';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductData } from './reduxSlices/productsSlice';
import { useEffect } from 'react';
import Loader from './components/commonComponents/Loader';
import ScrollToTop from './components/ScrollToTop';
import ProfilePage from './pages/ProfilePage';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';
import { setUser } from './reduxSlices/userSlice';
import Orders from './pages/Orders';
import AddReviewPage from './pages/AddReviewPage';
import FormDataPage from './pages/FormDataPage';
import Privacy from './components/commonComponents/menuPages/Privacy';
import Terms from './components/commonComponents/menuPages/Terms';
import Refund from './components/commonComponents/menuPages/Refund';
import About from './components/commonComponents/menuPages/About';
import Contact from './components/commonComponents/menuPages/Contact';
import Checkout from './pages/Checkout';
import AdminOrders from './pages/AdminOrders';
import Testing from './pages/Testing';

function App() {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.products);


  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const unsubscribeSnapshot = onSnapshot(
          doc(db, "users", user.uid),
          (userDoc) => {
            if (userDoc.exists()) {
              const userData = userDoc.data();
              dispatch(
                setUser({
                  name: userData.name,
                  email: userData.email,
                  uid: user.uid,
                  contact: userData.contact,
                })
              );
            }
          },
          (error) => {
            console.error("Error fetching user data:", error);
          }
        );

        return () => {
          unsubscribeSnapshot();
        };
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const argument = {
    type: "all",
    id: 1
  }

  useEffect(() => {
    dispatch(fetchProductData(argument));
  }, [dispatch])

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }


  return (
    <div className="App">
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/details/:name/:productId' element={<ProductDetail />} />
          <Route path='/products' element={<ProductPage />} />
          <Route path='/admin' element={<AdminPage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/addreview/:productid' element={<AddReviewPage />} />
          <Route path='/formdata' element={<FormDataPage />} />
          <Route path='/checkout' element={<Checkout />} />

          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/refund' element={<Refund />} />
          <Route path='/about' element={<About />} />
          <Route path='/contactus' element={<Contact />} />
          <Route path='/adminorder' element={<AdminOrders />} />

        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;



