import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './style.css';
import { auth, db } from "../../../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { setUser } from "../../../reduxSlices/userSlice";
import { useDispatch } from "react-redux";

const UserRegisterModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLogin, setIsLogin] = useState(false);
    const [reset, setReset] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    if (!isOpen) {
        return null;
    }

    const toggleMode = () => {
        setIsLogin(!isLogin);
        setName("");
        setEmail("");
        setPassword("");
        setPhoneNumber("");
    };

    const toggleReset = () => {
        setReset(!reset);
        setEmail("");
        setPassword("");
    }

    const handleSignUp = async () => {
        setLoading(true);
        if (!name || !email || !password || !phoneNumber) {
            toast.warning("Fill all details", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setLoading(false);
            return;
        }


        if (password.length <= 6) {
            toast.warning("Please keep password length more than 6", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setLoading(false);
            return;
        }

        if (name && email && password && password.length > 6 && phoneNumber) {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                const user = userCredential.user;

                await setDoc(doc(db, "users", user.uid), {
                    name: name,
                    email: user.email,
                    uid: user.uid,
                    contact: phoneNumber
                });

                console.log("user", user);
                dispatch(
                    setUser({
                        name: name,
                        email: user.email,
                        uid: user.uid,
                        contact: phoneNumber
                    })
                );

                toast.success("Profile Created Successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
                setLoading(false);
                setName('');
                setEmail('');
                setPassword('');
                setPhoneNumber('');
                onClose();
                navigate('/');
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
                setLoading(false);
                return;
            }
        }
    };


    const handleSignIn = async () => {
        setLoading(true);
        if (email && password) {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                const user = userCredential.user;

                const userDoc = await getDoc(doc(db, "users", user.uid));
                const userData = userDoc.data();

                dispatch(
                    setUser({
                        name: userData.name,
                        email: user.email,
                        uid: user.uid,
                        contact: userData.contact,
                    })
                );

                toast.success("Logged in successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    className: "toastify",
                });
                setLoading(false);
                setEmail('');
                setPassword('');
                onClose();
                navigate('/');
                return;
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
                setLoading(false);
                return;
            }
        } else if (!email || !password) {
            toast.warning("Please fill all details", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setLoading(false);
            return;
        }
    };

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            toast.success("Reset link sent to registered Email", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setReset(false);
            setIsLogin(true);
            setEmail('');
            setError(null);
            // setResetSent(true);
        } catch (error) {
            setError(error.message);
        }
    };


    return (
        <div className="modal" onClick={onClose}>
            <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
            >
                <span className="registerModalTitle">{reset ? "Reset Password" : isLogin ? 'Login' : 'Signup'}</span>
                {
                    reset ? (
                        <>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="modalinput"
                            />
                            <button onClick={handleResetPassword} className="modalBtn">Reset Password</button>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <div style={{ display: 'flex', width: '30%', justifyContent: 'center', gap: '30px', marginTop: '20px' }}><button className="modalBtn" onClick={() => setReset(!reset)}>Go to Login</button></div>

                        </>
                    ) :

                        isLogin ? (
                            // Login UI
                            <>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="modalinput"
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="modalinput"
                                />
                                <button className="modalBtn" onClick={handleSignIn} disabled={loading}>{loading ? "Loading" : "Login"}</button>
                                <p className="toggle-text" onClick={toggleMode}>
                                    Don't have an account? Register here.
                                </p>
                                <p className="toggle-text" onClick={toggleReset}>
                                    Forgot Password?
                                </p>
                            </>
                        ) : (
                            // Signup UI
                            <>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="modalinput"
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="modalinput"
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="modalinput"
                                />
                                <div className="customPhoneInput">
                                    <PhoneInput
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        country="in" // Set initial country code to India (+91)
                                    />
                                </div>
                                <button className="modalBtn" onClick={handleSignUp} disabled={loading}>{loading ? "Loading" : "Signup"}</button>
                                <p className="toggle-text" onClick={toggleMode}>
                                    Already have an account? Login
                                </p>
                            </>
                        )}
            </div>
        </div>
    );
};

export default UserRegisterModal;


