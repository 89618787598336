import React from 'react'
import './style.css'
import img1 from '../../../assets/productDetailds/ProductServiceTitleConsultation.png'
import img2 from '../../../assets/productDetailds/ProductServiceTitleCardPayment.png'
import img3 from '../../../assets/productDetailds/ProductServiceTitleCoin.png'

const ProductServiceTitle = () => {
    return (
        <div className='productServiceTitle'>
            <div>
                <img src={img1} alt='img1' />
                <span>Get Instant Advice from our support</span>
            </div>
            <div>
                <img src={img2} alt='img2' />
                <span>Safe and secure payment</span>
            </div>
            <div>
                <img src={img3} alt='img3' />
                <span>Get assured and guaranteed Payment</span>
            </div>
        </div>
    )
}

export default ProductServiceTitle


