import React from 'react'
import './style.css'
import RazorpayPayment from '../../../RazorpayPayment';
import { useDispatch } from 'react-redux';
import { setProductId } from '../../../reduxSlices/apiRequestTypeSlice';
import { useNavigate } from 'react-router';

const ProductPlans = ({ plans, productId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const keys = Object.keys(plans);

    const handleBuy = (price) => {
        dispatch(setProductId(productId));
        navigate('/checkout');
    }

    return (
        <div className='productPlansMainContainer' id='plans'>
            <span style={{ fontSize: '1.3rem', fontWeight: '600' }}>Pricing & Plans</span>
            {/* <RazorpayPayment/> */}
            <div className='plansMainContainer'>
                {
                    keys.map((key) => (
                        <div key={key} >
                            <div className='plansContainer'>
                                <div className='plansTitle'>{key}</div>
                                <div className='plansTextContainer'>{
                                    plans[key].slice(1).map((value) => (
                                        <span key={value} className='plansText'>{value}</span>
                                    ))
                                }</div>
                                <div style={{ padding: '1rem' }}>
                                    <div className='plansBottomContainer'>
                                        <span className='plansBottomText'><b>Calculated Price</b> (Excl. of all taxes)</span>
                                        <span className='plansPrice'>&#x20B9; {plans[key][0]}</span>
                                        <button onClick={() => handleBuy(parseInt(plans[key][0], 10))}>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ProductPlans




