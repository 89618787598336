import { configureStore } from "@reduxjs/toolkit";

import persistedApiRequestReducer from './reduxSlices/apiRequestTypeSlice'
import productsReducer from './reduxSlices/productsSlice'
import userReducer from './reduxSlices/userSlice'
import { persistStore } from "redux-persist";


const store = configureStore({
    reducer: {
        user: userReducer,
        apiRequestType: persistedApiRequestReducer,
        products: productsReducer,
    },
});

export const persistor = persistStore(store)

export default store;

