import React, { useState } from 'react'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { setProductId } from '../../../reduxSlices/apiRequestTypeSlice';
import GetDemo from '../../commonComponents/GetDemo';



const ProductLists = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const filterParam = useSelector((state) => state.apiRequestType.filterParam);
    const filterParamData = useSelector((state) => state.apiRequestType.filterParamData);


    if (filterParam === 'category') {
        var filteredProduct = data.filter((product) => product.category.toLowerCase().includes(filterParamData));
    } else if (filterParam === 'name') {
        var filteredProduct = data.filter((product) => product.name.toLowerCase().includes(filterParamData));
    } else if (filterParam === 'all') {
        var filteredProduct = data;
    } else if (filterParam === 'industry') {
        var filteredProduct = data.filter((product) => product.industry.toLowerCase().includes(filterParamData));
    } else if (filterParam === '') {
        var filteredProduct = data;
    }


    const truncateSentenceToLength = (sentence, maxLength) => {
        if (sentence.length <= maxLength) {
            return sentence;
        } else {
            return sentence.slice(0, maxLength) + '...';
        }
    }

    const openProductDetails = (product) => {
        navigate(`/details/${product.name}/${product.productId}`)
        dispatch(setProductId(product.productId))
    }

    return (
        <div className='productLists'>
            {filteredProduct.length === 0 ? <div style={{ marginLeft: '10%' }}>No Products found</div> :
                <div className='productContainer'>
                    {
                        filteredProduct.map((product, index) => (
                            <div className='productList' key={index}>
                                <div className='left'><img src={product.logo} className='productImg' alt='productLogo' onClick={() => openProductDetails(product)} /></div>
                                <div className='mid'>
                                    <span className='productName' onClick={() => openProductDetails(product)}>{product.name}</span>
                                    <span className='productBrand'>Brand: {product.brand}</span>
                                    <span className='productRating'>{product.rating}</span>
                                    <span className='productDesc'>{truncateSentenceToLength(product.desc, 200)}...<br /><span className='readMore' onClick={() => openProductDetails(product)}>Read More About {product.name}</span></span>
                                </div>
                                <div className='right'>
                                    {product.price === 0 ? <span className='productPrice'>Price on Request</span> : <span className='productPrice'>&#x20B9; {product.price}</span>}
                                    <button className='productDemoBtn' onClick={openModal}>Get Free Demo</button>
                                    <GetDemo isOpen={isModalOpen} onClose={closeModal} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default ProductLists



