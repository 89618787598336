import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/commonComponents/Loader';
import { useNavigate } from 'react-router';
import { setUser } from '../reduxSlices/userSlice';
// import RazorpayPayment from '../RazorpayPayment';
import Navbar from '../components/commonComponents/Navbar';
import Footer from '../components/commonComponents/Footer';
import PhonePePay from '../PhonePePay';

function Checkout() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const productId = useSelector((state) => state.apiRequestType.productId);
    const { data, status, error } = useSelector((state) => state.products);

    const [step, setStep] = useState(1);


    const navigate = useNavigate();
    const [plan, setPlan] = useState(0);
    const [planName, setPlanName] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);


    if (status === "loading") {
        return <Loader />;
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    if (status === 'succeeded') {
        var filteredProduct = data.filter(d => d.productId === productId);
        var keys = Object.keys(filteredProduct[0].plans);
    }



    const handleBuy = (price, key) => {
        console.log(price);
        setPlan(price);
        setPlanName(key);

        if (user) {
            setStep(3);
        } else {
            handleNext();
        }
    }


    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };



    const toggleMode = () => {
        setIsLogin(!isLogin);
        setName("");
        setEmail("");
        setPassword("");
        setPhoneNumber("");
    };


    const handleSignUp = async () => {
        setLoading(true);
        if (!name || !email || !password || !phoneNumber) {
            toast.warning("Fill all details", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setLoading(false);
            return;
        }


        if (password.length <= 6) {
            toast.warning("Please keep password length more than 6", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setLoading(false);
            return;
        }

        if (name && email && password && password.length > 6 && phoneNumber) {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                const user = userCredential.user;

                await setDoc(doc(db, "users", user.uid), {
                    name: name,
                    email: user.email,
                    uid: user.uid,
                    contact: phoneNumber
                });

                console.log("user", user);
                dispatch(
                    setUser({
                        name: name,
                        email: user.email,
                        uid: user.uid,
                        contact: phoneNumber
                    })
                );

                handleNext();
                toast.success("Profile Created Successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
                setLoading(false);
                setName('');
                setEmail('');
                setPassword('');
                setPhoneNumber('');
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
                setLoading(false);
                return;
            }
        }
    };



    const handleSignIn = async () => {
        setLoading(true);
        if (email && password) {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                const user = userCredential.user;

                const userDoc = await getDoc(doc(db, "users", user.uid));
                const userData = userDoc.data();

                dispatch(
                    setUser({
                        name: userData.name,
                        email: user.email,
                        uid: user.uid,
                        contact: userData.contact,
                    })
                );

                handleNext();
                toast.success("Logged in successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    className: "toastify",
                });
                setLoading(false);
                setEmail('');
                setPassword('');
                return;
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
                setLoading(false);
                return;
            }
        } else if (!email || !password) {
            toast.warning("Please fill all details", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            setLoading(false);
            return;
        }
    };



    const renderForm = () => {
        switch (step) {
            case 1:
                return (
                    <> {status === 'succeeded' &&
                        <StyledDiv>
                            {status === 'su'}
                            <span className='planTitle'>Choose Price Plan ({filteredProduct[0].name} Plans)</span>
                            <div className='plansMainContainer'>
                                {
                                    keys.map((key) => (
                                        <div key={key} >
                                            <div className='plansContainer'>
                                                <div className='plansTitle'>{key}</div>
                                                <div className='plansTextContainer'>{
                                                    filteredProduct[0].plans[key].slice(1).map((value) => (
                                                        <span key={value} className='plansText'>{value}</span>
                                                    ))
                                                }</div>
                                                <div style={{ padding: '1rem' }}>
                                                    <div className='plansBottomContainer'>
                                                        <span className='plansBottomText'><b>Calculated Price</b> (Excl. of all taxes)</span>
                                                        <span className='plansPrice'>&#x20B9; {filteredProduct[0].plans[key][0]}</span>
                                                        <button onClick={() => handleBuy(parseInt(filteredProduct[0].plans[key][0], 10), key)}>Choose Plan</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </StyledDiv>
                    }</>
                );
            case 2:
                return (
                    <StyledAuth>
                        <span className="registerModalTitle">{isLogin ? 'Login' : 'Signup'}</span>

                        <div className='mainBox'>
                            {
                                isLogin ? (
                                    // Login UI
                                    <div className='login'>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="modalinput"
                                        />
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="modalinput"
                                        />
                                        <button className="modalBtn" onClick={handleSignIn} disabled={loading}>{loading ? "Loading" : "Login"}</button>
                                        <p className="toggle-text" onClick={toggleMode}>
                                            Don't have an account? Register here.
                                        </p>
                                    </div>
                                ) : (
                                    // Signup UI
                                    <div className='signup'>
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="modalinput"
                                        />
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="modalinput"
                                        />
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="modalinput"
                                        />
                                        <div className="customPhoneInput">
                                            <PhoneInput
                                                placeholder="Phone Number"
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                                country="in" // Set initial country code to India (+91)
                                            />
                                        </div>
                                        <button className="modalBtn" onClick={handleSignUp} disabled={loading}>{loading ? "Loading" : "Signup"}</button>
                                        <p className="toggle-text" onClick={toggleMode}>
                                            Already have an account? Login
                                        </p>
                                    </div>
                                )}
                        </div>
                    </StyledAuth>
                );
            case 3:
                return (
                    <StyledPayBox>
                        <div className='right'>
                            <img src={filteredProduct[0].logo} />
                            <span className='title'>Order Summary</span>
                            <div className='productBoxTop'>
                                <span className='bold'>{filteredProduct[0].name}</span>
                                <span>{planName}</span>
                                <span className='bold'>&#x20B9; {plan.toFixed(2)}</span>
                            </div>

                            <div className='productBoxBottom'>
                                <div>
                                    <span>Order Total</span>
                                    <span >&#x20B9; {plan.toFixed(2)}</span>
                                </div>
                                <div>
                                    <span>GST 18%</span>
                                    <span >&#x20B9; {((plan * 18) / 100).toFixed(2)}</span>
                                </div>
                                <hr style={{ width: '100%' }} />
                                <div>
                                    <span className='bold'>Subtotal</span>
                                    <span className='bold'>&#x20B9; {(((plan * 18) / 100) + plan).toFixed(2)}</span>
                                </div>
                            </div>
                            <PhonePePay productId={productId} productName={filteredProduct[0].name} userId={user.uid} amount={plan} name={user.name} email={user.email} />
                        </div>
                    </StyledPayBox>
                );
            default:
                return null;
        }
    };

    return (
        <StyledBox>
            <Navbar />
            <div className='top'>
                <div className='topBox'>
                    <div className={`checkBox ${step >= 1 ? "active" : ""}`}>&#x2713;</div>
                    <span>Choose Product</span>
                </div>
                <div className='topBox'>
                    <div className={`checkBox ${step >= 2 ? "active" : ""}`}>&#x2713;</div>
                    <span>Create Account</span>
                </div>
                <div className='topBox'>
                    <div className={`checkBox ${step >= 3 ? "active" : ""}`}>&#x2713;</div>
                    <span>Pay for the Order</span>
                </div>
            </div>
            {renderForm()}
            <Footer />
        </StyledBox>
    );
}

export default Checkout;



const StyledPayBox = styled.div`
margin-left: 5%;
margin-right: 5%;
margin-bottom: 3rem;
width: 90%;
display: flex;
flex-direction: row;
justify-content: center;


.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    box-shadow: 0px 0px 3px 0px rgb(0, 0, 0.25);
    border-radius: 0.5rem;
    padding: 2rem 0.5rem;
}

@media(max-width: 600px) {
    .right {
        width: 70%;
    }
}

.right > img {
    width: 20%;
}

.bold {
    font-size: 1.1rem;
    font-weight: 600;
}

.title {
    font-size: 1.3rem;
    font-weight: 600;

}

.productBoxTop {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 5% 10%;
    width: 70%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
}

.productBoxBottom {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 90%;
}

.productBoxBottom > div {
    display: flex;
    justify-content: space-between;
}

`


const StyledBox = styled.div`

margin-top: 120px;
width: 100%;

.top{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
}

.checkBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: lightgrey;
    border: 0.05rem solid white;
    border-radius: 50%;
}

.topBox > span {
    font-size: 1rem;
    font-weight: 600;
}

.active {
    background-color: orange;
    color: white;
}


.topBox {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}


`



const StyledDiv = styled.div`

margin-left: 5%;
margin-right: 5%;
margin-top: 5rem;
margin-bottom: 5rem;
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;

.plansMainContainer {
    grid-template-columns: 1fr 1fr 1fr;
}

.planTitle {
    font-size: 1.3rem;
    font-weight: 600;
}

@media(max-width: 600px) {
    .plansMainContainer {
        grid-template-columns: 1fr 1fr;
    }
}

`

const StyledAuth = styled.div`

margin-left: 5%;
margin-top: 5rem;
margin-bottom: 5rem;
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 2rem;

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.mainBox  {
    width: 100%;
}

input, button, .customPhoneInput {
    width: 60%;
}

@media(max-width: 600px) {
    input, button, .customPhoneInput {
        width: 80%;
    }
}

.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



`


