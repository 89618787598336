import React, { useState } from 'react'
import './style.css'
import displayReviewProfileImg from '../../../dummy-assets/displayReviewProfileImg.png';
import arrowLeft from '../../../assets/arrow-left.png';
import arrowRight from '../../../assets/arrow-right.png';
import { displayReviewText } from '../../../data/textContent';
import { useTransition, animated } from 'react-spring';

const DisplayReview = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  const prevReview = (n) => {
    setDirection(-1);
    setCurrentIndex(currentIndex === 0 ? n : currentIndex - 1);
  }

  const nextReview = (n) => {
    setDirection(1);
    setCurrentIndex(currentIndex === n ? 0 : currentIndex + 1);
  }

  const transitions = useTransition(displayReviewText[currentIndex], {
    from: { opacity: 0, transform: `translateX(${direction * 100}%)` },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: `translateX(${direction * -100}%)` },
    config: { duration: 1000 },
  });

  return (
    <div className='displayReview'>
      <span className='displayReviewTitle'>What people say about us</span>
      <div className='displayReviewContent'>
        <div className='displayReviewArrowLeft' onClick={() => prevReview(displayReviewText.length - 1)}>
          <img src={arrowLeft} alt='arrowLeft' />
        </div>
        <div className='displayReviewContainer'>
          {transitions((style, item) => (
            <animated.div style={style} className="review">
              <img src={displayReviewProfileImg} className='displayReviewProfileImg' alt='reviewImg' />
              <div className='displayReviewText'>{item}</div>
            </animated.div>
          ))}
        </div>

        <div className='displayReviewArrowRight' onClick={() => nextReview(displayReviewText.length - 1)}>
          <img src={arrowRight} alt='arrowRight' />
        </div>
      </div>
    </div>
  )
}

export default DisplayReview

