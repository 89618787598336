import React, { useState } from 'react'
import './style.css'
import herotopToggle from '../../../assets/heroTopToggle.png'

const ProductFAQ = ({ faq }) => {
    const [currentIndex, setCurrentIndex] = useState(0);


    return (
        <div className='productFaq'>
            <span className='title'>Frequently Asked Questions</span>
            <div className='mainContainer'>
                <div className='left'>
                    {
                        faq.map((product, index) => (
                            <div key={index} onClick={() => setCurrentIndex(index)} className={`que ${index === currentIndex ? 'active' : ''}`}>
                                <span>{product.question}</span>
                                <img src={herotopToggle} className='toggle' alt='toggle'/>
                            </div>
                        ))
                    }
                </div>
                <div className='right'>
                    <span>{faq[currentIndex].question}</span>

                    <span>{faq[currentIndex].answer}</span>
                </div>
            </div>
        </div>
    )
}

export default ProductFAQ
