import React from 'react'
import './style.css'
import android from '../../../assets/featureAndSpec/android.png'
import businesbag from '../../../assets/featureAndSpec/businesbag.png'
import cloud from '../../../assets/featureAndSpec/cloud.png'
import desktop from '../../../assets/featureAndSpec/desktop.png'
import language from '../../../assets/featureAndSpec/language.png'
import phone from '../../../assets/featureAndSpec/phone.png'
import setting from '../../../assets/featureAndSpec/setting.png'
import window from '../../../assets/featureAndSpec/window.png'

const ProductSpec = () => {
    return (
        <div className='productSpec'>
            <div className='title'>Specifications</div>
            <div className='mainContainer'>
                <div className='left'>
                    <span>Supported Platforms:</span>
                    <span>Device:</span>
                    <span>Deployment:</span>
                    <span>Suitable For:</span>
                    <span>Business Specific:</span>
                    <span>Language:</span>
                </div>
                <div className='right'>
                    <span><img src={window} alt='icon' />&nbsp;Windows &nbsp; <img src={android} alt='icon' /> &nbsp; Android</span>
                    <span><img src={desktop} alt='icon' />&nbsp;Desktop &nbsp; <img src={phone} alt='icon' /> &nbsp; Mobile</span>
                    <span><img src={cloud} alt='icon' />&nbsp; Web-Based, Perpetual</span>
                    <span><img src={setting} alt='icon' />&nbsp; All Industries</span>
                    <span><img src={businesbag} alt='icon' />&nbsp; All Businesses</span>
                    <span><img src={language} alt='icon' />&nbsp;  English</span>
                </div>
            </div>
        </div>
    )
}

export default ProductSpec
