import React, { useEffect } from 'react'
import Navbar from '../components/commonComponents/Navbar'
import HeroSectionTop from '../components/commonComponents/HeroSectionTop'
import HeroSection from '../components/homePageComponents/HeroSection'
import ServiceTitle from '../components/homePageComponents/ServiceTitle'
import PopularCategories from '../components/homePageComponents/PopularCategories'
import SearchBasedProducts from '../components/homePageComponents/SearchBasedProducts'
import WriteReview from '../components/homePageComponents/WriteReview'
import TopSellingProducts from '../components/homePageComponents/TopSellingProducts'
import DisplayReview from '../components/homePageComponents/DisplayReview'
import SellProducts from '../components/homePageComponents/SellProducts'
import AskQuestions from '../components/homePageComponents/AskQuestions'
import Footer from '../components/commonComponents/Footer'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/commonComponents/Loader'
import { setFilterParam, setFilterParamData } from '../reduxSlices/apiRequestTypeSlice'


const HomePage = () => {
    const dispatch = useDispatch();
    const { data, status, error } = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(setFilterParam(''));
        dispatch(setFilterParamData(''));
    }, [])

    if (status === "loading") {
        return <Loader />;
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='homePage'>
            {status === 'succeeded' &&
                <>
                    <Navbar />
                    <HeroSectionTop />
                    <HeroSection />
                    <ServiceTitle />
                    <PopularCategories />
                    <SearchBasedProducts data={data} />
                    <WriteReview data={data} />
                    <TopSellingProducts data={data} />
                    <DisplayReview />
                    <SellProducts />
                    <AskQuestions />
                    <Footer />
                </>
            }
        </div>
    )
}

export default HomePage
