import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    requestType: 'add',
    productId: 0,
    productName: '',
    filterParam: '',
    filterParamData: '',
};

const apiRequestTypeSlice = createSlice({
    name: "apiRequestType",
    initialState,
    reducers: {
        setApiRequestType: (state, action) => {
            state.requestType = action.payload;
        },
        clearApiRequestType: (state) => {
            state.requestType = '';
        },
        setProductId: (state, action) => {
            state.productId = action.payload;
        },
        clearProductId: (state) => {
            state.productId = 0;
        },
        setFilterParam: (state, action) => {
            state.filterParam = action.payload;
        },
        clearFilterParam: (state) => {
            state.filterParam = '';
        },
        setFilterParamData: (state, action) => {
            state.filterParamData = action.payload;
        },
        clearFilterParamData: (state) => {
            state.filterParamData = '';
        },
        setProductName: (state, action) => {
            state.productName = action.payload;
        },
        clearProductName: (state) => {
            state.productName = '';
        }
    },
});

const persistConfig = {
    key: 'apiRequest',
    storage,
};

const persistedApiRequestReducer = persistReducer(persistConfig, apiRequestTypeSlice.reducer);


export const { setApiRequestType, clearApiRequestType, setProductId, clearProductId, setFilterParam, clearFilterParam, setFilterParamData, clearFilterParamData, setProductName, clearProductName } = apiRequestTypeSlice.actions;
export default persistedApiRequestReducer;


