import React, { useRef, useState } from 'react'
import './style.css'
import heroTopToggle from '../../../assets/heroTopToggle.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { setFilterParam, setFilterParamData } from '../../../reduxSlices/apiRequestTypeSlice'

const HeroSectionTop = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isIndustryOpen, setIsIndustryOpen] = useState(false);
  const industryRef = useRef(null);

  const toggleIndustryDropdown = () => {
    setIsIndustryOpen(!isIndustryOpen);
  };

  const closeIndustryDropdown = () => {
    setIsIndustryOpen(false);
  };

  const handleClick = (industry) => () => {
    dispatch(setFilterParam('industry'));
    dispatch(setFilterParamData(industry));
    navigate('/products');
    setIsIndustryOpen(false);
  };


  const handleMouseEnter = () => {
    if (!isIndustryOpen) {
      toggleIndustryDropdown();
    }
  };


  const handleMouseLeave = (event) => {
    if (!industryRef.current.contains(event.relatedTarget)) {
      closeIndustryDropdown();
    }
  };


  return (
    <div className='heroTop'>
      <div>
        <span>All Categories</span>
        <img src={heroTopToggle} alt='toggle' />
      </div>
      <div>
        <span>All Brands</span>
        <img src={heroTopToggle} alt='toggle' />
      </div>
      <div className='industry'
        onMouseEnter={toggleIndustryDropdown}
        onMouseLeave={closeIndustryDropdown}
        ref={industryRef}
      >
        <span>By Industry</span>
        <img src={heroTopToggle} alt='toggle' />

        {isIndustryOpen && (
          <div className='industry-dropdown'>
            <span onClick={handleClick('finance')}>Finance</span>
            <span onClick={handleClick('architecture')}>Architecture</span>
            <span onClick={handleClick('retail')}>Retail</span>
            <span onClick={handleClick('education')}>Education</span>
            <span onClick={handleClick('all')}>All</span>
            <span onClick={handleClick('smes')}>SMEs</span>
            <span onClick={handleClick('manufacturing')}>Manufacturing</span>
            <span onClick={handleClick('hospitality')}>Hospitality</span>
            <span onClick={handleClick('service')}>Service Industry</span>
            <span onClick={handleClick('healthcare')}>Healthcare</span>
            <span onClick={handleClick('e-commerce')}>E-commerce</span>
            <span onClick={handleClick('legal')}>Legal Firms</span>
          </div>
        )}
      </div>
      {/* <div>Compare Product</div> */}
      <div>Offers</div>
      {/* <div>Videos</div> */}
      <div>Blog</div>
    </div>
  )
}

export default HeroSectionTop




