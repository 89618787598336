import React, { useState } from 'react'
import './style.css'
import Rating from '@mui/material/Rating';
import { recommendBtnArray } from '../../../data/textContent';
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import { addReviewToProduct } from '../../../api-services/productData';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';


const AddReviewContent = () => {
    const navigate = useNavigate();

    const [feature, setFeature] = useState(0)
    const [valueMoney, setValueMoney] = useState(0)
    const [ease, setEase] = useState(0)
    const [support, setSupport] = useState(0)
    const [recommendScore, setRecommendScore] = useState(0);
    const [title, setTitle] = useState('');
    const [pros, setPros] = useState('');
    const [cons, setCons] = useState('');
    const [activeBtn, setActiveBtn] = useState(null);

    const productId = useSelector((state) => state.apiRequestType.productId);
    const { data, status, error } = useSelector((state) => state.products);


    if (status === "loading") {
        return <Loader />;
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    const handleBoxClick = (i, score) => {
        setActiveBtn(i)
        setRecommendScore(score)
    }

    const handleSubmitBtn = async () => {
        const reviewObject = {
            title: title,
            pros: pros,
            cons: cons,
            feature: feature,
            valueForMoney: valueMoney,
            easeOfUse: ease,
            customerSupport: support,
            recommendationScale: recommendScore,
        }

        try {
            const review = await addReviewToProduct(reviewObject, productId);

            if (review) {
                setPros('');
                setCons('');
                setRecommendScore(0);
                setEase(0);
                setFeature(0);
                setTitle('');
                setValueMoney(0);
                setSupport(0);
                setActiveBtn(null);

                toast.success("Review Added Successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
            }
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
        }
    }


    if (status === 'succeeded') {
        var filteredProduct = data.filter(d => d.productId === productId);
        console.log(filteredProduct[0])
    }



    return (
        <div className="addReviewContent">
            {status === 'succeeded' &&
                <>
                    <div className='addReviewContainerTop'>
                        <img src={filteredProduct[0].logo} />
                        <div>
                            <span className='name'>{filteredProduct[0].name}</span>
                            <span className='brand'>{filteredProduct[0].brand}</span>
                            {/* <button>Change Product</button> */}
                        </div>
                    </div>
                    <div className='addReviewContainer1'>
                        <span>Review Title</span>
                        <textarea placeholder='What most important to know?' value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className='addReviewContainer2'>
                        <div className='addReviewContainer2Child'>
                            <div>
                                <span>Features</span>
                                <Rating
                                    name="simple-controlled"
                                    value={feature}
                                    onChange={(event, newValue) => {
                                        setFeature(newValue);
                                    }}
                                />
                            </div>
                            <div>
                                <span>Value for money</span>
                                <Rating
                                    name="simple-controlled"
                                    value={valueMoney}
                                    onChange={(event, newValue) => {
                                        setValueMoney(newValue);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='addReviewContainer2Child'>
                            <div>
                                <span>Ease of Use</span>
                                <Rating
                                    name="simple-controlled"
                                    value={ease}
                                    onChange={(event, newValue) => {
                                        setEase(newValue);
                                    }}
                                />
                            </div>
                            <div>
                                <span>Customer Support</span>
                                <Rating
                                    name="simple-controlled"
                                    value={support}
                                    onChange={(event, newValue) => {
                                        setSupport(newValue);
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    <div className='addReviewContainer3'>
                        <span>How likely are you to recommend {feature} to others ?</span>
                        <div className='reviewRecommendBtnContainer'>
                            {
                                recommendBtnArray.map((btn, i) => (
                                    <div className={i == activeBtn ? 'reviewRecommendBtn activeBtn' : "reviewRecommendBtn"} onClick={() => handleBoxClick(i, btn)}>{btn}</div>
                                ))
                            }
                        </div>
                    </div>

                    <div className='addReviewContainer4'>
                        <span>Pros</span>
                        <textarea placeholder='What do you like most about it?' value={pros} onChange={(e) => setPros(e.target.value)} />
                    </div>


                    <div className='addReviewContainer5'>
                        <span>Cons</span>
                        <textarea placeholder='What do you like least about it?' value={cons} onChange={(e) => setCons(e.target.value)} />
                    </div>
                    <button className='addReviewBtn' onClick={handleSubmitBtn}>Submit Review</button>
                </>
            }
        </div >
    )
}

export default AddReviewContent;


