import React from 'react'
import './style.css'
import img from '../../../assets/productDetailds/ProductServiceTitleBusinessmanThinking.png'

const ProductPricing = ({ price, name }) => {
    return (
        <>
            {price === 0 &&
                <div className='productPricing'>
                    <>
                        <div className='productPricingLeft'>
                            <span className='productPricingTitle'>{name} Pricing & Plans</span>
                            <span className='productPricingSubtitle'>{name} price is available on request, Looking for pricing details, customization requirements or have other queries? We are just a click away.</span>
                            <button className='productPricingBtn'>Request for Price</button>
                        </div>
                        <div className='productPricingRight'>
                            <img src={img} alt='pricingImage' />
                        </div>
                    </>
                </div>
            }
        </>
    )
}

export default ProductPricing

