import React, { useEffect, useState } from 'react'
import './style.css'
import { softwareCategories } from '../../../data/textContent'
import { useTransition, animated } from 'react-spring';
import arrowLeft from '../../../assets/arrow-left.png';
import arrowRight from '../../../assets/arrow-right.png';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterParam, setFilterParamData } from '../../../reduxSlices/apiRequestTypeSlice';
import { useNavigate } from 'react-router';


const PopularCategories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [pageIndex, setPageIndex] = useState(0);

    const calculateProducts = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 2000 && screenWidth > 1000) {
            setItemsPerPage(4);
        } else if(screenWidth <= 1000 && screenWidth > 500) {
            setItemsPerPage(3);
        } else if (screenWidth <= 500) {
            setItemsPerPage(2);
        }
    }


    useEffect(() => {
        calculateProducts();
        window.addEventListener('resize', calculateProducts);

        return () => {
            window.removeEventListener('resize', calculateProducts)
        };
    }, [])


    const filterParam = useSelector((state) => state.apiRequestType.filterParam);
    const filterParamData = useSelector((state) => state.apiRequestType.filterParamData);

    const handleCategoryClick = (endpoint) => {
        dispatch(setFilterParam('category'));
        dispatch(setFilterParamData(endpoint));
        navigate('/products');
        console.log("para", filterParam)
        console.log("data", filterParamData)
    }


    const transitions = useTransition(
        softwareCategories.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage),
        {
            from: { opacity: 0, transform: 'translateX(100%)' },
            enter: { opacity: 1, transform: 'translateX(0%)' },
            leave: { opacity: 0, transform: 'translateX(-100%)' },
            config: { duration: 500 },
        }
    );

    const nextPage = () => {
        setPageIndex((pageIndex + 1) % Math.ceil(softwareCategories.length / itemsPerPage));
    };

    const prevPage = () => {
        setPageIndex((pageIndex + softwareCategories.length - 1) % Math.ceil(softwareCategories.length / itemsPerPage));
    };



    return (
        <div className='popularCategories'>
            <div className='popularCategoriesTopContainer'>
                <span className='popularCategoriesTitle'>Popular Categories</span>
                {/* <span className='popularCategoriesRight'>See All</span> */}
            </div>
            <div className='popularCategoriesContent'>
                <div className='popularCategoriesArrowLeft' onClick={() => prevPage()}>
                    <img src={arrowLeft} alt='arrowLeft' />
                </div>
                <div className='popularCategoriesContainer'>
                    {transitions((style, item) => (
                        <animated.div style={style} className="imageContainer" key={item} onClick={() => handleCategoryClick(item.endpoint)}>
                            <img src={item.image} alt='img' />
                            <div className='popularCategoriesImgText'>{item.category}</div>
                        </animated.div>
                    ))}
                </div>
                <div className='popularCategoriesArrowRight' onClick={() => nextPage()}>
                    <img src={arrowRight} alt='arrowRight' />
                </div>
            </div>
        </div>
    )
}

export default PopularCategories
