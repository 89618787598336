import React, { useState } from 'react';
import styled from 'styled-components';
import { loadScript } from './utils'; // Define this utility function
import logo from '../src/assets/logo.jpg'
import { toast } from 'react-toastify';
import { addOrder } from './api-services/productData';
import { useNavigate } from 'react-router';
import emailjs from '@emailjs/browser';

function RazorpayPayment({ productId, productName, userId, amount, name, email }) {
    const [payment, setPayment] = useState(null);
    const navigate = useNavigate();

    const handleOrderEmail = (rzpPaymentId) => {

        const serviceId = 'service_ix474zx';
        const templateId = 'template_fwbgdyl';
        const publicKey = 'FNrwU6YMel3pMRyiy';


        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'Digitalholder Team',
            message: `Order placed by ${name} for ${productName} with payment id ${rzpPaymentId} and amount ${(((amount * 118) / 100)).toFixed(2)}, please proceed with further process by contacting customer at ${email}`,
        }

        // Send the email using EmailJS
        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('Order Placed and email sent to the team to proceed with further process', response);
            })
            .catch((error) => {
                console.error('Facing some error:', error);
            });
    }

    const options = {
        key: 'rzp_test_IwVZ80srHTMOR2', // Replace with your Razorpay key
        amount: ((((amount * 118) / 100)).toFixed(2) * 100), // Amount in paise (e.g., 10000 paise = ₹100)
        currency: 'INR', // Change the currency as needed
        name: 'Digital Holder',
        description: `Product Purchase for ProductId: ${productId}`,
        image: logo, // URL of your company logo
        handler: async function (response) {

            try {
                handleOrderEmail(response.razorpay_payment_id);

                const orderData = {
                    productId: productId,
                    userId: userId,
                    amount: amount,
                    paymentId: response.razorpay_payment_id,
                }
                console.log(response);
                const order = await addOrder(orderData);
                toast.success(`Payment Successful. Payment ID: ${response.razorpay_payment_id}`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    className: "toastify",
                });
                navigate('/orders');

            } catch (e) {
                console.error('Error saving order:', e);
            }
        },
        prefill: {
            name: name, // User's name
            email: email, // User's email
        },
    };

    const openPaymentModal = () => {
        if (!payment) {
            loadScript('https://checkout.razorpay.com/v1/checkout.js', () => {
                const rzp = new window.Razorpay(options);
                rzp.open();
                setPayment(rzp);
            });
        } else {
            payment.open();
        }
    };

    return (
        <StyledDiv>
            <button onClick={openPaymentModal}>Pay &#x20B9; {(((amount * 118) / 100)).toFixed(2)}</button>
        </StyledDiv>
    );
}

export default RazorpayPayment;

const StyledDiv = styled.div`
margin-top: 2rem;
margin-bottom: 3rem;
display: flex;
justify-content: center;
align-items: center;

button {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #000080;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

`

