import React from 'react'
import Navbar from '../components/commonComponents/Navbar'
import AddReviewContent from '../components/commonComponents/AddReviewContent'

const AddReviewPage = () => {
    return (
        <div>
            <Navbar />
            <AddReviewContent />
        </div>
    )
}

export default AddReviewPage

