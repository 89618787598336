import React, { useState } from 'react'
import './style.css'
import logo from '../../../assets/logo.jpg'
import navbarMenuIcon from '../../../assets/navbarMenuIcon.png'
import navbarProfileIcon from '../../../assets/navbarProfileIcon.png'
import { useNavigate } from 'react-router'
import UserRegisterModal from '../../auth/UserRegisterModal'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'firebase/auth'
import { auth } from '../../../firebaseConfig'
import { toast } from 'react-toastify'
import { clearUser } from '../../../reduxSlices/userSlice'
import GetAdviceModal from '../GetAdviceModal'
import SellProductModal from '../SellProductModal'



const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user)



  // Modal content 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdviceModalOpen, setIsAdviceModalOpen] = useState(false)
  const [isSellModalOpen, setIsSellModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openAdviceModal = () => {
    setIsAdviceModalOpen(true);
  }

  const closeAdviceModal = () => {
    setIsAdviceModalOpen(false);
  }

  const openSellModal = () => {
    setIsSellModalOpen(true);
  }

  const closeSellModal = () => {
    setIsSellModalOpen(false);
  }


  //Navigate to the user profile
  const gotoProfile = () => {
    navigate('/profile')
  }

  //Go to the cart
  const goOrders = () => {
    navigate('/orders')
  }

  //User Signout
  const userSignout = () => {
    let timeoutId = setTimeout(() => {
      signOut(auth)
        .then(() => {
          toast.success("Logged Out successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "toastify",
          });
          navigate("/");
          dispatch(clearUser());
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "toastify",
          });
        });
    }, 200);

    return () => clearTimeout(timeoutId);
  }

  const gotoAdminPage = () => {
    navigate('/admin');
  }


  return (
    <div className='navbar'>
      <div className='logoContainer'>
        <img src={logo} className='logo' onClick={() => navigate('/')} alt='websiteLogo' />
        <div className='logoText'><span>Digital </span> <span>Holder</span></div>
      </div>
      <div className='navBtn'>
        <div className='navAdviceBtn' onClick={() => openAdviceModal()}>Get Free Advice</div>
        <div className='navSellBtn' onClick={() => openSellModal()}>Sell A Product</div>
        <GetAdviceModal isOpen={isAdviceModalOpen} onClose={closeAdviceModal} />
        <SellProductModal isOpen={isSellModalOpen} onClose={closeSellModal} />
      </div>

      <div className='navProfile'>
        <img src={navbarMenuIcon} alt='menuIcon' />
        <img src={navbarProfileIcon} alt='profileIcon' />
        <UserRegisterModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
      {user ? (
        user.email === "digitalholder505@gmail.com" ? (
          <div class="dropdown" id="dropdown">
            <span onClick={gotoProfile}>Profile</span>
            <span onClick={gotoAdminPage}>Admin</span>
            <span onClick={goOrders}>Orders</span>
            <span onClick={userSignout}>Signout</span>
          </div>
        )
          : (
            <div class="dropdown" id="dropdown">
              <span onClick={gotoProfile}>Profile</span>
              <span onClick={goOrders}>Orders</span>
              <span onClick={userSignout}>Signout</span>
            </div>
          ))
        : (
          <div class="dropdown" id="dropdown">
            <span onClick={openModal}>Signup/Login</span>
            <span onClick={openModal}>Profile</span>
            <span onClick={openModal}>Orders</span>
          </div>
        )}
    </div >
  )
}

export default Navbar

