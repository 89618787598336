import React, { useEffect, useState } from 'react'
import './style.css'
import { getFormData } from '../../../api-services/productData';

const FormData = () => {
    const [formType, setFormType] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    const handleGetData = async () => {

        const formData = await getFormData();

        const filtered = formData.data.filter(item => (
            (!startDate || formatDate(item.time) >= startDate) &&
            (!endDate || formatDate(item.time) <= endDate) &&
            (!formType || item.queryType === formType)
        ));
        setFilteredData(filtered);
    }


    console.log(filteredData)

    const formatDate = (data) => {
        const date = new Date(data);
        const timeZoneOffset = -330;
        date.setMinutes(date.getMinutes() + timeZoneOffset);
        const formattedDateTime = date.toISOString().split('T')[0];

        return formattedDateTime;
    }


    console.log(formType)
    return (
        <div className='formDataContainer'>
            <div className='formData1'>
                <select
                    value={formType}
                    onChange={(e) => setFormType(e.target.value)}
                >
                    <option>Select Request Type</option>
                    <option value="advice">Advice Request</option>
                    <option value="sell">Sell Product Request</option>
                    <option value="demo">Demo Request</option>
                    <option value="que">Question Request</option>
                    <option value="price">Price Request</option>
                </select>
                <input type='date'
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <input type='date'
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
                <button onClick={() => handleGetData()}>Get Data</button>
            </div>
            <div className='formData2'>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>QueryFor</th>
                            <th>QueryType</th>
                            <th>Message</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.length != 0 && filteredData.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.name}</td>
                                    <td>{data.email}</td>
                                    <td>{data.contact}</td>
                                    <td>{data.queryFor}</td>
                                    <td>{data.queryType}</td>
                                    <td>{data.message}</td>
                                    <td>{formatDate(data.time)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FormData;

