import http from './httpService';
import { api } from './apiConfig';

const apiEndpoint = api.baseUrl;


// Add product to database 
export const addProduct = (productDetails) => {
    return http.post(`${apiEndpoint}/add/products`, productDetails)
}

// get all products from database
export const getProducts = () => {
    return http.get(`${apiEndpoint}/get/products`)
}

// get products from database by productId
export const getProductsByProductId = (productid) => {
    return http.get(`${apiEndpoint}/get/products/productid/${productid}`)
}

// get products from database by name
export const getProductsByName = (name) => {
    return http.get(`${apiEndpoint}/get/products/name/${name}`)
}

// get products from database by category
export const getProductsByCategory = (category) => {
    return http.get(`${apiEndpoint}/get/products/category/${category}`)
}

// get products from database by industry
export const getProductsByIndustry = (industry) => {
    return http.get(`${apiEndpoint}/get/products/industry/${industry}`)
}

// get products from database by brand
export const getProductsByBrand = (brand) => {
    return http.get(`${apiEndpoint}/get/products/brand/${brand}`)
}

// add reviews to a particular product by productId
export const addReviewToProduct = (review, productid) => {
    return http.post(`${apiEndpoint}/add/review/${productid}`, review)
}

// update product details by productId
export const updateProductDetails = (productDetails, productid) => {
    return http.put(`${apiEndpoint}/update/products/${productid}`, productDetails)
}

// delete product by productId
export const deleteProductByProductId = (productid) => {
    return http.delete(`${apiEndpoint}/delete/products/${productid}`)
}

// add form data to database
export const addFormData = (data) => {
    return http.post(`${apiEndpoint}/addformdata`, data)
}

// get form data from database
export const getFormData = () => {
    return http.get(`${apiEndpoint}/getformdata`)
}

// add order to orders
export const addOrder = (data) => {
    return http.post(`${apiEndpoint}/addorder`, data)
}


// get orders by userId
export const getOrder = (userId) => {
    return http.get(`${apiEndpoint}/getorder/${userId}`)
}

// get all orders from Database
export const getAllOrder = () => {
    return http.get(`${apiEndpoint}/getorder`)
}


// pay for orders
export const payOrder = (reqData) => {
    return http.post(`${apiEndpoint}/initiate-payment`, reqData)
}


// check payment status
export const payStatus = (merchantId, merchantTransactionId) => {
    return http.get(`${apiEndpoint}/payment-status/${merchantId}/${merchantTransactionId}`)
}
