import React, { useState } from 'react'
import './style.css'
import AskQuestionImg from '../../../assets/askQuestionImg.png'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';
import { addFormData } from '../../../api-services/productData';


const AskQuestions = () => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleAskQuestion = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };


  const handleSubmit = async () => {
    try {

      if (name && email && phoneNumber && phoneNumber.length > 2 && message) {

        const data = {
          name: name,
          email: email,
          contact: phoneNumber,
          message: message,
          queryType: 'que'
        }

        const submitedData = await addFormData(data);

        toast.success("We will contact you soon", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "toastify",
        });

        handleCloseModal();
        setMessage('');

      } else {
        toast.warning("Please Fill all details", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "toastify",
        });
      }

    } catch (e) {
      toast.error(e.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "toastify",
      });
      console.log("Error", e)
    }
  }


  return (
    <div className='askQues'>
      <img className='askQuesLeft' src={AskQuestionImg} alt='askQueImage' />
      <div className='askQuesRight'>
        <div>
          <span>Have a question in mind?</span>
          <br />
          <span>Get real answers from verified users and our software experts</span>
        </div>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder='Type Here...'
          rows={5}
        />
        <button onClick={handleAskQuestion}>Ask your question</button>

        {
          open && <div className="modal" onClick={handleCloseModal}>
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="registerModalTitle">Please enter your details and Questions</span>
              <>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="modalinput"
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="modalinput"
                />
                <div className="customPhoneInput">
                  <PhoneInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    country="in" // Set initial country code to India (+91)
                  />
                </div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder='Type Here...'
                  rows={5}
                  style={{ width: "75%", margin: '1rem', padding: '0.4rem' }}
                />
                <button className="modalBtn" onClick={handleSubmit}>Ask your Question</button>
              </>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default AskQuestions

