import React, { useEffect, useState } from 'react'
import './style.css'
import { storage } from '../../../firebaseConfig';
import { addProduct, getProductsByProductId, updateProductDetails } from '../../../api-services/productData';
import { toast } from 'react-toastify';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { setApiRequestType } from '../../../reduxSlices/apiRequestTypeSlice';
import { useNavigate } from 'react-router';


const ProductDetailsForm = () => {
    const apiRequestType = useSelector((state) => state.apiRequestType.requestType);
    const user = useSelector((state) => state.user.user);
    const [productIdInput, setProductIdInput] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (user && user.email != "digitalholder505@gmail.com") {
        navigate('/');
    }



    const [productData, setProductData] = useState({
        productId: '',
        logo: '',
        images: [],
        videoUrl: '',
        name: '',
        brand: '',
        category: '',
        industry: '',
        price: 0,
        desc: '',
        features: [],
        faq: [],
        plans: '',
    });

    const handleLogoChange = async (event) => {
        try {
            const { name, value } = event.target;
            const logoFiles = event.target.files[0];

            const logoRef = ref(storage, `productLogo/${productData.productId}/${logoFiles.name}`);
            await uploadBytes(logoRef, logoFiles);

            const logoUrl = await getDownloadURL(logoRef);

            console.log("Logo", logoUrl)
            setProductData((prevData) => ({
                ...prevData,
                logo: logoUrl,
            }))
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    }


    const handleInputChange = async (event) => {
        const { name, value, type } = event.target;

        if (type === 'file') {
            const imageFiles = Array.from(event.target.files);

            try {
                const downloadURLs = await Promise.all(
                    imageFiles.map(async (imageFile) => {
                        const imageRef = ref(storage, `productImages/${productData.productId}/${imageFile.name}`);
                        await uploadBytes(imageRef, imageFile);

                        const imageUrl = await getDownloadURL(imageRef); // Use the same reference to get the URL
                        return imageUrl;
                    })
                );


                console.log("Images", downloadURLs);

                setProductData((prevData) => ({
                    ...prevData,
                    images: [...prevData.images, ...downloadURLs],
                }));
            } catch (error) {
                console.error('Error uploading images:', error);
            }
        } else if (name === 'features') {
            // Split input value into an array and update state
            setProductData({
                ...productData,
                [name]: value.split(', ')
            });
        } else if (name === "faq") {
            // Parse the comma-separated string into an array of objects
            const faqArray = value.split(',').map(item => {
                const [question, answer] = item.split(':');
                return { question, answer };
            });

            setProductData(prevData => ({
                ...prevData,
                faq: faqArray,
            }));
        } else if (name === 'plans') {

            // Split the input value into an array by lines
            const lines = value.split('-');

            // Initialize an empty object to store the formatted data
            const formattedData = {};

            // Iterate through each line and format it as needed
            lines.forEach((line) => {
                const parts = line.split(':');
                if (parts.length === 2) {
                    const key = parts[0].trim();
                    const values = parts[1].trim();
                    const valueArray = values.split(',').map((item) => item.trim());
                    formattedData[key] = valueArray;
                }
            });

            setProductData(prevData => ({
                ...prevData,
                plans: formattedData,
            }))

        } else {
            // Handle other fields
            setProductData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const originalInputValue = Object.entries(productData.plans)
        .map(([key, values]) => `${key}: ${values.join(', ')}`)
        .join('-');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            if (apiRequestType === 'add') {
                const response = await addProduct(productData);

                if (response.status === 200 || response.status === 201) {
                    console.log('Product added successfully');
                    toast.success("Product added successfully")
                    setProductData({
                        productId: '',
                        logo: '',
                        images: [],
                        videoUrl: '',
                        name: '',
                        brand: '',
                        category: '',
                        industry: '',
                        price: 0,
                        desc: '',
                        features: [],
                        faq: [],
                        plans: '',
                    });
                } else {
                    console.error('Error adding product');
                    toast.error("error");
                }
            } else if (apiRequestType === 'update') {
                const response = await updateProductDetails(productData, productIdInput)

                if (response.status === 200 || response.status === 201) {
                    console.log('Product updated successfully');
                    toast.success("Product updated successfully");

                    setProductData({
                        productId: '',
                        logo: '',
                        images: [],
                        videoUrl: '',
                        name: '',
                        brand: '',
                        category: '',
                        industry: '',
                        price: 0,
                        desc: '',
                        features: [],
                        faq: [],
                        plans: '',
                    });

                    setProductIdInput(0);
                } else {
                    console.error('Error updating product');
                    toast.error("error");
                }
            }

        } catch (error) {
            console.error('Error:', error);
            toast.error("error");
        }
    };

    const fetchDataToUpdate = async () => {
        const fetchedProductData = await getProductsByProductId(productIdInput);
        const data = fetchedProductData.data[0];
        console.log(fetchedProductData.data[0])
        toast.success("Data fetched, Now you can update")

        setProductData({
            productId: data.productId,
            logo: data.logo,
            images: data.images,
            videoUrl: data.videoUrl,
            name: data.name,
            brand: data.brand,
            category: data.category,
            industry: data.industry,
            price: data.price,
            desc: data.desc,
            features: data.features,
            faq: data.faq,
            plans: data.plans,
        });
    }

    useEffect(() => {
        setProductData({
            productId: '',
            logo: '',
            images: [],
            videoUrl: '',
            name: '',
            brand: '',
            category: '',
            industry: '',
            price: 0,
            desc: '',
            features: [],
            faq: [],
            plans: '',
        });
        setProductIdInput(0);
    }, [apiRequestType])



    return (
        <div className='productDetailsFormContent'>
            <div className='updateRequestContainer'>
                <select
                    value={apiRequestType}
                    onChange={(e) => dispatch(setApiRequestType(e.target.value))}
                >
                    <option value="add">Add</option>
                    <option value="update">Update</option>
                </select>
                <input type='number' placeholder='productId' disabled={apiRequestType === 'add' || apiRequestType === ''} value={productIdInput} onChange={(e) => setProductIdInput(e.target.value)} />
                <button onClick={() => fetchDataToUpdate()} disabled={apiRequestType === 'add' || apiRequestType === ''} style={{ backgroundColor: '#FFB21D', borderStyle: 'none', padding: '0.5rem', fontSize: '1.2rem', fontWeight: '600', borderRadius: '0.5rem', cursor: 'pointer' }}>Fetch Data</button>
            </div>
            <div className='container'>
                <form onSubmit={handleSubmit} className='productDetailsForm'>
                    {/* Product ID */}
                    <label>
                        Product ID:
                        <input
                            type="number"
                            name="productId"
                            value={productData.productId}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Logo */}
                    <label>
                        Logo (select one logo):
                        <input
                            type="file"
                            name="logo"
                            onChange={handleLogoChange}
                            required
                        />
                    </label>
                    {/* Images */}
                    <label>
                        Images (select multiple):
                        <input
                            type="file"
                            name="images"
                            multiple
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* videoUrl */}
                    <label>
                        VideoUrl:
                        <input
                            type="text"
                            name="videoUrl"
                            value={productData.videoUrl}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    {/* Name */}
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={productData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Brand */}
                    <label>
                        Brand:
                        <input
                            type="text"
                            name="brand"
                            value={productData.brand}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Category */}
                    <label>
                        Category:
                        <input
                            type="text"
                            name="category"
                            value={productData.category}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Industry */}
                    <label>
                        Industry:
                        <input
                            type="text"
                            name="industry"
                            value={productData.industry}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Price */}
                    <label>
                        Price:
                        <input
                            type="number"
                            name="price"
                            value={productData.price}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Description */}
                    <label>
                        Description:
                        <textarea
                            name="desc"
                            value={productData.desc}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* Features */}
                    <label>
                        Features (comma-separated):
                        <textarea
                            type="text"
                            name="features"
                            value={productData.features.join(', ')}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    {/* FAQ */}
                    <label>
                        FAQ (comma-separated):
                        <textarea
                            type="text"
                            name="faq"
                            value={productData.faq.map(item => `${item.question}:${item.answer}`).join(',')}
                            onChange={handleInputChange}
                            required
                        />
                    </label>

                    <label>
                        Plans
                        <textarea
                            type="text"
                            name='plans'
                            value={originalInputValue}
                            onChange={handleInputChange}
                            placeholder="Enter data in the format: key1: value1, value2, value3... (one line per key-value pair)"
                            rows={5}
                            cols={50}
                        ></textarea>
                    </label>

                    <button type="submit" className='addProductBtn'>{apiRequestType} Product</button>
                </form>
                <div className='right'>
                    <div className='logo'><span >Logo:</span>{productData.logo && <img src={productData.logo} className='adminPanelProductLogo' />}</div>
                    <span style={{ height: "50px", fontSize: '1.2rem', fontWeight: '600' }}>Images:</span>
                    <div className='adminPanelProductImageContainer'>
                        {
                            productData.images.length > 0 && productData.images.map((img, index) => (
                                <img src={img} className='adminPanelProductImage' />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailsForm





