import React, { useState } from 'react'
import './style.css'
import heroRight from '../../../assets/heroRight.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { setFilterParam, setFilterParamData } from '../../../reduxSlices/apiRequestTypeSlice'

const HeroSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');

  const handleSearch = () => {
    dispatch(setFilterParam('name'));
    dispatch(setFilterParamData(inputValue));
    navigate('/products')
  }

  return (
    <div className='heroSection'>
      <div className='heroLeft'>
        <div className='heroTitle'>Digital Holder Helps You to Buy Right Software For Your Business Growth</div>
        <div className='heroInputContainer'>
          <input className='heroInput' placeholder='Search by Name...' onChange={e => setInputValue(e.target.value)} />
          <button className='heroSearchBtn' onClick={() => handleSearch()}>Search</button>
        </div>
      </div>
      <div className='heroRight'>
        <img src={heroRight} alt='heroImg' />
      </div>
    </div>
  )
}

export default HeroSection



