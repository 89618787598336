import React from 'react'
import './style.css'
import logo from '../../../assets/logo.jpg'
import facebook from '../../../assets/footer/facebook.png'
import insta from '../../../assets/footer/insta.png'
import twitter from '../../../assets/footer/twitter.png'
import linkedin from '../../../assets/footer/linkedin.png'
import youtube from '../../../assets/footer/youtube.png'
import { useNavigate } from 'react-router'

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className='footer'>
      <div className='footer1'>
        <a href='https://www.facebook.com/digitalholder?mibextid=ZbWKwL' target='_blank'><img src={facebook} alt='facebook' /></a>
        <a href='https://instagram.com/digitalholderofficial?igshid=OGQ5ZDc2ODk2ZA==' target='_blank'><img src={insta} className='insta' alt='insta' /></a>
        <a href='https://x.com/digital_holder?t=_pghp-qOsV7zswT4T-yp8g&s=09' target='_blank'><img src={twitter} alt='twitter' /></a>
        <a href='https://www.linkedin.com/company/digitalholder/' target='_blank'><img src={linkedin} alt='linkedin' /></a>
        <a href='https://youtube.com/@digitalholder?si=q-yETTOeWIVynKkO' target='_blank'><img src={youtube} alt='youtube' /></a>
      </div>
      <div className='footer2'>
        {/* <span onClick={() => navigate('/about')}>About</span> */}
        <span onClick={() => navigate('/contactus')}>Contact us</span>
        {/* <span onClick={() => navigate('/')}>Support</span> */}
        <span onClick={() => navigate('/refund')}>Cancellation & Refund Policy</span>
        <span onClick={() => navigate('/terms')}>Terms & conditions</span>
        <span onClick={() => navigate('/privacy')}>Privacy Policy</span>
      </div>
    </div>
  )
}

export default Footer


