import React from 'react'
import styled from 'styled-components'
import Navbar from '../Navbar'
import Footer from '../Footer'

const Terms = () => {
    return (
        <div>
            <Navbar />
            <StyledContent>

                <span className='mainTitle'>Terms of Use :-</span>
                <span className='title'>Last Updated on 05-09-2023</span>

                <span className='text'>Welcome to the Digitalholder.in Website. These terms, conditions, and notices (“Terms”) govern your access to and use of the Digitalholder.in website, including access and use through any other website or platforms or mobile applications, (“Site”).</span>

                <span className='text'>"Conditions of Use" constitute an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.</span>

                <span className='title'>Use Of the Site :-</span>

                <ol type='a'>
                    <li>Your use of this site, which is provided by Digital Holder by using this site you signify your consent to be bound by these terms and conditions.</li>
                    <li>If you do not agree with any part of the following terms and conditions you must not use this site. Some areas of the site require registration.</li>
                    <li>You agree, understand and acknowledge that the website is an online platform that enables you to purchase products listed on the website at the price indicated therein at any time from any location within the jurisdiction as set forth below.</li>
                    <li>By completing the initial registration form and by entering your email address and password, you will be deemed to have accepted these terms and conditions. Also, by subscribing to any of our email services, you are deemed to have accepted these terms and conditions. If you register with this site, you should read and adhere to our privacy policy</li>
                    <li>The right to use this website does not include any resale or commercial use of this website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.</li>
                    <li> Digital Holder reserves the right to modify these terms of use at any time without giving prior notice. Your use of this Site following such modification constitutes you agree to those terms.</li>
                    <li>We reserve the right to refuse service, terminate accounts, or remove or edit content if you are in breach of applicable laws, these Conditions of Use, or any other applicable terms and conditions, guidelines, or policies.</li>
                </ol>

                <span className='title'>Copyright and Trademark:-</span>
                <span className='text'>All contents of this Site are © Copyright 2023 Digital holder. All Rights Reserved. Digital Holder is not responsible for content on websites, platforms, or mobile applications operated by parties other than Digital Holder, all other product or service names, logos, or slogans displayed on this Site are registered and/or common law trademarks of Digital Holder, or its suppliers or licensors and may not be copied, imitated, or used, in whole or in part, without the prior written permission of Digital Holder or the applicable trademark holder. In addition, the look and feel of this Site, including all page headers, custom graphics, button icons, and scripts, is the service mark, trademark, and/or trade dress of Digital Holder and may not be copied, imitated, or used, in whole or in part, without the prior written permission of Digital Holder. All other trademarks, registered trademarks, product names, and company names or logos mentioned in or on this Site are the property of their respective owners. Reference to any products, services, processes, or other information, by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation thereof by the Digital Holder</span>

                <span className='text'>Other logos and product and company names mentioned herein may be the trademarks of their respective owners.</span>
                <span className='text'>If you are aware of an infringement of either your brand or our brand, please let us know at contact@digitalholder.in</span>

                <span className='title'>General</span>

                <ol type='a'>
                    <li>Digital holder makes no representation that materials in the Platform are appropriate or available for use in other locations/Countries other than India. Those who choose to access this site from other locations/Countries other than India do so on their own initiative.</li>
                    <li>Nothing in these terms and conditions affects your statutory rights as a Consumer.</li>
                    <li>If any provision in this Agreement is held to be invalid or unenforceable, it shall be deemed severed from the Agreement and this shall not affect the validity or enforceability of the remaining provisions.</li>
                    <li>Any waiver of a breach of this Agreement must be in writing.</li>
                    <li>Any variation of this Agreement must be in writing and signed by a duly authorized Digital holder official.</li>
                    <li>Any notices given under this Agreement shall be in writing and sent to the authorized mail id provided on the website.</li>
                    <li>These terms and conditions shall be governed by and construed in accordance with the laws of India and the parties submit to the non-exclusive jurisdiction of the Kolkata Courts.</li>
                </ol>

                <span className='title'>Reviews and Comments:-</span>

                <ol type='a'>
                    <li>Visitors may post reviews, comments and other content; and submit suggestions, ideas, comments, questions or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of "spam"</li>
                    <li>You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of the content.</li>
                    <li>If you post customer reviews, comments, customer questions or answers, or other content generated by you for display on the website (including any images, video or audio, all together "content", you grant Digital holder (a) a non-exclusive, royalty-free license to use, reproduce, publish, make available, translate and modify such content throughout the world (including the right to sublicense these rights to third parties) and (b) the right to use the name that you submit in connection with such content. No moral rights are transferred by this provision.</li>
                </ol>


                <span className='title'>Prices:-</span>

                <ol type='a'>
                    <li>Goods and services are invoiced at the price prevailing at the time of acceptance of the order.</li>
                    <li>The prices displayed are exclusive of any applicable taxes and delivery charges in the case of hardware products.</li>
                    <li>Digital holder reserves the right to modify the prices from time to time.</li>
                    <li>Despite our best efforts, a small number of the items in our catalogue may be mispriced. We will verify pricing when processing your order and before we take payment. If we have made a mistake and a product's correct price is higher than the price on the website, we may contact you before dispatch to request whether you want to buy the product at the correct price or cancel your order. If a product's correct price is lower than our stated price, we will charge the lower amount and send you the product</li>
                </ol>

                <span className='title'>Links to other websites:-</span>

                <ol type='1'>
                    <li>This site may contain links to third-party websites (“other sites”) that are not under Digital Holder’s control</li>
                    <li>Digital holder makes no claim or responsibility regarding the quality, nature, and reliability of the other sites that are accessible by hyperlinks from the sites or links to the sites.</li>
                    <li>Digital holder provides these links to you as a convenience and inclusion of any link does not imply endorsement by Digital holder as of other sites or any association with operators of such other site.</li>
                    <li>You are responsible for viewing and abiding by the privacy statements and terms of use posted at any third-party sites.</li>
                </ol>


                <span className='title'>Orders:-</span>

                <ol type='1'>
                    <li>All contracts of sale made by Digital holder shall be deemed to incorporate these terms and conditions, which shall prevail over any other terms from the party ("the Customer") with whom Digital holder is dealing.</li>
                    <li>The prices, price offers and descriptions of Goods or other services listed and mentioned in the Seller's online shop do not constitute an offer.</li>
                    <li>All orders are subject to acceptance and availability of the goods ordered: Digital holder is entitled to refuse any order placed by you.</li>
                    <li>Digital holder reserves the right to refuse service to anyone for any reason at any time.</li>
                    <li>You undertake that:
                        <ul>
                            <li>All details you provide to us to purchase goods or services offered on our website are correct and the user accepts that full liability for orders placed using a valid user-id and password lies with you and not Digital holder</li>
                            <li>The credit or debit card you use to purchase from us is your own card or your company's card, which you are authorized to use it, and there are sufficient funds or credit facilities to cover the cost of any goods or services you order from us. We reserve the right to obtain validation of your credit or debit card details before providing you with any goods or services.</li>
                            <li>You may need your Digital holder account to use certain Digital Holder Services, and you may be required to be logged into the account and have a valid payment method associated with it.</li>
                            <li>Please note, Digital holder may record and/or monitor inbound and outbound calls and electronic traffic for training purposes</li>
                            <li>Digital Holder’s privacy policy shall apply to orders placed. A copy of the policy can be found here: https://www.Digitalholder.in/privacy</li>
                            <li>All software is sold subject to the software publisher’s end user licence agreement (EULA), the terms of which the Customer agrees to adhere to.</li>
                        </ul>
                    </li>
                </ol>

                <span className='title'>Payment:-</span>

                <ol type='1'>
                    <li>Payment is due on presentation of invoice unless credit terms have been agreed in writing with Techjockey. Techjockey shall invoice Customer: on acceptance of the Customer’s order in respect of software; on the shipment in respect of hardware; on completion of professional services or monthly in arrears in respect of recurring services unless otherwise agreed in writing and in advance in respect of maintenance and support services.</li>
                    <li>If payment is not made on the due date, Techjockey will be entitled to charge the interest calculated daily and any additional damages due to late payment by the consumer at Techjockey’s discretion.</li>
                    <li>While availing any of the payment method/s available on the Platform, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:

                        <ol type='a'>
                            <li>Lack of authorization for any transaction/s, or</li>
                            <li>Exceeding the limit mutually agreed by You and between "Bank/s", or</li>
                            <li>Any payment issues arising out of the transaction, or</li>
                            <li>Decline of transaction for any other reason/s.</li>
                        </ol>
                    </li>
                    <li>Before shipping / delivering your order to you, Seller may request you to provide supporting documents (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment instrument used by you for your purchase. This is done in the interest of providing a safe online shopping environment to Our Users.</li>
                    <li>You have specifically authorized Digital holder or its service providers to collect, process, facilitate and remit payments and/or the Transaction Price electronically or through Cash on Delivery to and from other Users in respect of transactions through Payment Facility.</li>
                    <li>You understand, accept and agree that the payment facility provided by Digital holder is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment through Cash On Delivery, collection and remittance facility for the Transactions on the Digital holder Platform using the existing authorized banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment Facility, Digital holder is neither acting as trustees nor acting in a fiduciary capacity with respect to the Transaction or the Transaction Price.</li>
                    <li>Digital holder reserves the right to refuse to process Transactions by Buyers with a prior history of questionable charges including without limitation breach of any agreements by Buyer with Digital holder or breach/violation of any law or any charges imposed by Issuing Bank or breach of any policy.</li>
                    <li>As required by applicable law, if the Customer makes a purchase of an amount equal to or above INR 50,000.00, the Customer will be required to update the PAN number.</li>
                    <li>All Valid Credit / Debit/ Cash Card/ and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms and conditions agreed to between the Buyer and the respective Issuing Bank and payment instrument issuing company.</li>
                    <li>All online bank transfers from valid bank accounts are processed using the gateway provided by the respective issuing bank which supports payment facility to provide these services to the users. All such online bank transfers on payment facility are also governed by the terms and conditions agreed to between the buyer and the respective issuing bank.</li>
                </ol>

                <span className='title'>Product Specifications:-</span>
                <ol type='1'>
                    <li>Digital Holder makes every effort to supply the goods as advertised but reserves the right to supply the goods subject to minor variations in actual dimensions and specifications where these are changed by the manufacturer.</li>
                    <li>Unless expressly indicated otherwise, Digital holder is not the manufacturer of the products and services sold on this website. While we work to ensure that product information on our website is correct, actual product packaging and materials may contain more and different information to that displayed on our website. All information about the products on our website is provided for information purposes only. We recommend that you do not rely solely on the information presented on our website. Please always read labels, warnings and directions provided with the product before use.</li>
                </ol>

                <span className='title'>Liability Disclaimer:-</span>

                <ol type='1'>
                    <li>We will do our utmost to ensure that availability of the Digital Holder Services will be uninterrupted and that transmissions will be error-free. However, due to the nature of the internet, this cannot be guaranteed. Also, your access to Digital Holder Services may be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services.</li>
                    <li>In its dealings with Business Customers, Digital Holder shall under no circumstances be liable for any consequential or indirect damage or loss, however, caused, including (but not restricted to) loss of business or profits, loss of goodwill, damage to trading relationships loss of data and other financial loss. ("Financial loss" in this sense does not refer to the price you have paid for the goods, which we may be liable to refund to you, in whole or in part, if the goods are faulty or do not comply with their description). Digital Holder liability in respect of all other losses shall be limited to the invoiced amount of the relevant order.</li>
                    <li>Nothing in these conditions limits or excludes our responsibility for fraudulent representations made by us or for death or personal injury caused by our negligence or wilful misconduct.</li>
                </ol>


                <span className='title'>Confidential information and data protection:-</span>

                <ol type='1'>
                    <li>Each party shall preserve the confidentiality of all confidential information of the other which it receives, keep such information secure and protected against theft, damage, loss or unauthorised access, and not use such information for any purpose except as contemplated by the Contract.</li>
                    <li>Each party shall ensure that such obligations are observed by its employees, officers, agents and contractors.</li>
                    <li>Each Party shall process personal data in accordance with the Data Protection Legislation (which includes applicable data protection legislation including the I T Act, 2000(21 of 2000), General Data Protection Regulation ((EU) 2016/679) (GDPR), and any national or state laws, regulations and secondary legislation) as amended from time to time.</li>
                    <li>Personal data processing will be accomplished through electronic and non-electronic means, for the purpose of these terms and conditions. Users shall be responsible for obtaining the consent of all Customer-related data subjects whose personal data is provided to or otherwise made available to Digital Holder according to these terms and conditions or any order.</li>
                    <li>Customer authorises Digital Holder to engage sub-processors to the extent required for the performance of these terms and conditions and/or any order.</li>
                    <li>Digital Holder shall in respect of any personal data of the Customer processed under these terms and conditions to maintain such personal data under appropriate, commercially reasonable and sufficient technical and organisational security measures to protect such personal data or information and both Parties warrant to have taken all appropriate registrations under relevant laws.</li>
                    <li>Notwithstanding any other provision of these terms and conditions, Customer agrees that Digital Holder shall not be considered a data processor or data controller or in any other way have any responsibilities or liability (and the Customer holds Digital Holder harmless) in respect of the processing of personal data pursuant to a Product or Service (including cloud service) provided by a third party supplier of product or services transacted by Digital Holder and where Digital Holder is not processing such data. Such processing of personal data shall be subject to the arrangements and contract terms entered into directly between the Customer and the third-party provider.</li>
                </ol>


                <span className='title'>Service Help :-</span>
                <span className='text'>For answers to your questions or ways to contact us, email us at contact@digitalholder.in </span>
                <span className='text'>Or, you can write to us at:-
                    <b>  Godrej Genesis Building 11th Floor Salt Lake Sector v Near College More Kolkata West Bengal 700091</b>
                </span>
            </StyledContent>
            <Footer />
        </div>
    )
}

export default Terms


const StyledContent = styled.div`

margin-top: 100px;
display: flex;
flex-direction: column;
margin-left: 5%;
margin-right: 5%;
margin-bottom: 50px;
gap: 1.5rem;


.text {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.mainTitle {
    font-size: 1.5rem;
    font-weight: 800;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

ol, ul {
    font-size: 0.9rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

`

