import React, { Fragment, useEffect, useState } from 'react'
import './style.css'
import arrowLeft from '../../../assets/arrow-left.png'
import arrowRight from '../../../assets/arrow-right.png'
// import { useTransition, animated } from 'react-spring/'
import discountIcon from '../../../assets/productDetailds/discountIcon.png'
import planIcon from '../../../assets/productDetailds/planIcon.png'
import percentageIcon from '../../../assets/productDetailds/percentageIcon.png'
import GetDemo from '../../commonComponents/GetDemo'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { setProductId, setProductName } from '../../../reduxSlices/apiRequestTypeSlice'

const ProductDetails = ({ productId, logo, images, name, brand, category, industry, rating, price, desc, reviews }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [direction, setDirection] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dimension, setDimension] = useState(false);


    const calculateProducts = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth <= 600) {
            setDimension(true);
        } else if (screenWidth > 600) {
            setDimension(false);
        }
    }


    useEffect(() => {
        calculateProducts();
        window.addEventListener('resize', calculateProducts);

        return () => {
            window.removeEventListener('resize', calculateProducts)
        };
    }, [])


    const openModal = (prodName) => {
        dispatch(setProductName(prodName))
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const prevImg = (n) => {
        // setDirection(-1);
        setCurrentIndex(currentIndex === 0 ? n : currentIndex - 1);
    }

    const nextImg = (n) => {
        // setDirection(1);
        setCurrentIndex(currentIndex === n ? 0 : currentIndex + 1);
    }

    const truncateSentenceToLength = (sentence, maxLength) => {
        if (sentence.length <= maxLength) {
            return sentence;
        } else {
            return sentence.slice(0, maxLength) + '...';
        }
    }

    // function call on click of Read more text in desc 
    const navigateToDesc = () => {

    }

    const addReview = () => {
        dispatch(setProductId(productId));
        navigate(`/addreview/${productId}`);
    }

    // const transitions = useTransition(productDetailtImgs[currentIndex], {
    //     from: { opacity: 0, transform: `translateX(${direction * 100}%)` },
    //     enter: { opacity: 1, transform: "translateX(0%)" },
    //     leave: { opacity: 0, transform: `translateX(${direction * -100}%)` },
    //     config: { duration: 1000 },
    // });
    var overAllRating = 0;
    var reviewLength = reviews.length;
    reviews && reviews.forEach((review) => {
        overAllRating = overAllRating + ((review.feature + review.valueForMoney + review.easeOfUse + review.customerSupport) / 4) / reviewLength;
    })

    const smoothScrollTo = (elementId) => {
        const element = document.getElementById(elementId);
        const offset = 70; // Set your desired offset here

        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };


    const handleBuy = (price) => {

        dispatch(setProductId(productId));
        navigate('/checkout');
    }


    return (
        <div className='productDetails'>
            <div className='productDetailsLeft'>
                <div className='productDetailsImgMainContainer'>
                    <div className='productDetailsImgArr'>
                        {images.slice(0, 5).map((img, index) => (
                            <img key={index} src={img} onClick={() => setCurrentIndex(index)} alt='smallImg' />
                        ))}
                    </div>
                    <div className='productDetailsImgSlider'>
                        <div className='productDetailsArrowLeft' onClick={() => prevImg(images.length - 1)}>
                            <img src={arrowLeft} alt='arrowLeft' />
                        </div>
                        {/* <div className='productDetailsImgContainer'>
                            {transitions((style, item) => (
                                <animated.div style={style} className="productDetailsMainImg">
                                    <img src={item} />
                                </animated.div>
                            ))}
                        </div> */}
                        <div className='productDetailsImgContainer'>
                            <img src={images[currentIndex]} className='productDetailsMainImg' alt='productImg' />
                        </div>

                        <div className='productDetailsArrowRight' onClick={() => nextImg(images.length - 1)}>
                            <img src={arrowRight} alt='arrowRight' />
                        </div>
                    </div>
                </div>
                <div className='productDetailsBtn'>
                    <button onClick={() => openModal(name)}>Get Free Demo</button>
                    <button onClick={() => handleBuy(parseInt(price, 10))}>Buy Now</button>
                </div>
                <GetDemo isOpen={isModalOpen} onClose={closeModal} />
            </div>


            <div className='productDetailsRight'>
                <div className='productDetailsRightTop'>
                    <span>{category} &gt; {name}</span>
                    {dimension == true && <div>
                        <img src={logo} style={{ width: '20%' }} />
                    </div>}
                    <Fragment>
                        <span>{name}</span>
                        <span>by: {brand}</span>
                    </Fragment>
                    <div className='productDetailsratingContainer'>
                        <div>{overAllRating}</div>
                        <div> {reviews.length} Reviews</div>
                        <div onClick={() => addReview()}>Write a review</div>
                    </div>
                    {price === 0 ? <span className='productDetailsPrice'>Price on Request</span> : <span className='productDetailsPrice'>&#x20B9; {price} excl. GST</span>}
                    {price === 0 ? <span className='productDetailsPrice'>Price on Request</span> : <span className='productDetailsPriceLite'>&#x20B9; {Math.round((price * 118) / 100)} incl. GST</span>}
                    <div className='productDetailsBottomBtn'>
                        <button><img src={discountIcon} alt='discountIcon' />Get Discount</button>
                        <button onClick={() => smoothScrollTo('plans')}><img src={planIcon} alt='planIcon' />View Plans</button>
                    </div>
                </div>
                <div className='productDetailsRightBottom'>
                    <div>
                        <span>Save Extra with Offers</span>
                        <span><img src={percentageIcon} alt='percentageIcon' />&nbsp;Save upto 28%, Get GST Invoice on your business purchase |
                            <span className='tc'> T&C</span></span>
                    </div>
                    <div className='productDetailsDesc'>
                        {truncateSentenceToLength(desc, 200)}
                        <span onClick={() => smoothScrollTo('productSpec')} style={{ cursor: 'pointer' }}>Read more</span>
                    </div>
                    {dimension == true && <div className='dimensionBox'>
                        <div className='productDetailsBtn'>
                            <button onClick={() => openModal(name)}>Get Free Demo</button>
                            <button onClick={() => handleBuy(parseInt(price, 10))}>Buy Now</button>
                        </div>
                    </div>}
                    <GetDemo isOpen={isModalOpen} onClose={closeModal} />
                </div>

            </div>
        </div>
    )
}

export default ProductDetails

