import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProducts, getProductsByProductId } from "../api-services/productData";

export const fetchProductData = createAsyncThunk(
    "product/fetchProductData",
    async (requistType) => {
        if (requistType.type === 'all') {
            const products = await getProducts();

            return products.data;
        }
    }
);


const productsSlice = createSlice({
    name: 'products',
    initialState: {
        data: [],
        status: "idle",
        error: null,
    },
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(fetchProductData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProductData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchProductData.rejected, (state, action) => {
                console.log("Rejected with:", action.error.message);
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default productsSlice.reducer;


