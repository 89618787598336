import React, { useEffect } from 'react'
import Navbar from '../components/commonComponents/Navbar'
import { setFilterParam, setFilterParamData } from '../reduxSlices/apiRequestTypeSlice';
import { useDispatch } from 'react-redux';
import OrderContent from '../components/OrderContent';
import Footer from '../components/commonComponents/Footer';

const Orders = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFilterParam(''));
        dispatch(setFilterParamData(''));
    }, [])

    return (
        <div className='cartPage'>
            <Navbar />
            <OrderContent />
            <Footer />
        </div>
    )
}

export default Orders


