import React, { useState } from 'react'
import './style.css'
import { useNavigate } from 'react-router'

const OrderNavbar = () => {
    const navigate = useNavigate();
    const [switchPage, setSwitchPage] = useState(false);


    const goToHomepage = () => {
        navigate('/');
    }

    const goToAdminPage = () => {
        navigate('/admin')
    }

    const goToFormDataPage = () => {
        navigate('/formdata')
    }


    return (
        <div className='adminNavbar'>
            <div><span><span style={{ color: '#20276B', fontSize: '2rem', fontWeight: '600' }}>DIGITAL <span style={{ color: '#FFB21D', fontSize: '2rem', fontWeight: '600' }}>HOLDER :-</span></span><span style={{ fontSize: '2rem', fontWeight: '500' }}>  Admin Panel</span></span> <><span className='adminPanelProductpage' onClick={() => goToAdminPage()}>Admin Panel</span>  <span className='adminPanelHomepage' onClick={() => goToHomepage()}>HomePage</span>  <span className='adminPanelFormData' onClick={() => goToFormDataPage()}>FormData</span></></div>
        </div>
    )
}

export default OrderNavbar