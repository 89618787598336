import React, { useState } from 'react'
import './style.css'
import GetDemo from '../../commonComponents/GetDemo';
import { useDispatch } from 'react-redux';
import { setProductName } from '../../../reduxSlices/apiRequestTypeSlice';

const ProductDemo = ({ name, videoUrl }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (prodName) => {
        dispatch(setProductName(prodName));
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className='productDemo'>
            <div className='productDemoLeft'>
                <span className='productDemoTitle'>Get {name} Demo</span>
                <span className='productDemoSubtitle'>We make it happen! Get your hands on the best solution based on your needs.</span>
                <button className='productDemoBtn' onClick={() => openModal(name)}>Request Demo</button>
                <GetDemo isOpen={isModalOpen} onClose={closeModal} />
            </div>
            <div className='productDemoRight'>
                <iframe
                    width="100%"
                    height="100%"
                    src={videoUrl}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                </iframe>
            </div>
        </div>
    )
}

export default ProductDemo


