import React, { useState } from 'react'
import './style.css'
import sellProductImg from '../../../assets/sellProductImg.png'
import SellProductModal from '../../commonComponents/SellProductModal';

const SellProducts = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='sellProduct'>
            <div className='sellProductLeft'>
                <span>Grow your business faster</span>
                <br />
                <span>By Selling your Software</span>
                <button onClick={openModal}>Start Selling</button>
                <SellProductModal isOpen={isModalOpen} onClose={closeModal} />
            </div>
            <img src={sellProductImg} className='sellProductRight' alt='sellProductImg' />
        </div>
    )
}

export default SellProducts



