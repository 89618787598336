import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import styled from 'styled-components'

const Privacy = () => {
    return (
        <div>
            <Navbar />
            <StyledContent>
                <span className='mainTitle'>Privacy Policy</span>
                <span className='title'>Last Updated on 05-09-2023</span>

                <span className='text'>Digital Holder is committed to protecting and respecting your privacy. For the purposes of data protection legislation, we are the data controller and we will process your Personal Data in accordance with applicable laws and regulations. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</span>

                <span className='title'>How we collect information</span>

                <ul>
                    <li>From you directly and through this site: Digital Holder may collect information through the website when you visit. The data we collect depends on the context of your interactions with our website.</li>
                    <li>Through business interaction: We may collect information through your or your employer’s business interaction with our employees or offices.</li>
                    <li>From other sources: We may receive information from other sources, such as public databases, joint marketing partners, social media platforms, or other third parties such as updated delivery and address information from our carriers or other third parties.</li>
                </ul>


                <span className='title'>Information we collect</span>
                <span className='text'>We collect information primarily to provide better services to all of our customers. We collect the information you provide to us on our Site, such as your name, email address, postal address, phone number, company name, payment information, and demographics. We may also obtain information from other sources and combine it with information we collect on our Site.</span>
                <span className='text'>When you visit our Site, some information is automatically collected. This may include information such as the Operating System (OS) running on your device, Internet Protocol (IP) address, access times, browser type, and language, and the website you visited before our Site. We also collect information about how you use Digital Holder product(s).</span>

                <span className='title'>How we use information</span>
                <span className='text'>We use the information we collect primarily to provide, maintain, protect, and improve our current products and services. We use personal information collected through our Site as described in this Policy or on our Site. For example, we may use your information to improve our services, understand and enhance your experience using our Site, personalize our products, and provide customer support.</span>

                <span className='title'>Data Transfer</span>
                <span className='text'>We do not sell your personal data to others. We share your personal data with your consent or to complete any transaction or provide any product or service you have requested or authorized. We may also share data with our affiliates, subsidiaries, and vendors working on our behalf.</span>


                <span className='title'>Cookies & Tracking</span>
                <span className='text'>To optimize our web presence, we use cookies. These cookies allow us to improve your access to our site and provide you with essential features and services.</span>
                <span className='text'>You can prevent the storage of cookies by choosing a "disable cookies" option in your browser settings. However, this may limit the functionality of our Internet offers.</span>


                <span className='title'>Legal basis for processing personal data</span>
                <span className='text'>We process personal data for various purposes, including for the performance of contracts, compliance with legal obligations, protection of vital interests, legitimate interests, and with your consent.</span>

                <span className='title'>Data Security</span>
                <span className='text'>Digital Holder takes measures to protect customer data and prevent unauthorized access or loss of data. We use encryption and maintain physical, electronic, and procedural safeguards.</span>

                <span className='title'>Sharing of personal data</span>
                <span className='text'>We do not share your personal data with third parties without your prior consent, except for specific circumstances outlined in our policy.</span>

                <span className='title'>Children</span>
                <span className='text'>If you are under 18 or the age of majority in your jurisdiction, you may only use Digital Holder's website and services with the consent of your parent or legal guardian.</span>

                <span className='title'>Your information choices and changes</span>
                <span className='text'>You can make choices about the collection and processing of your data by Digital Holder. You can access your personal data and opt-out of certain services. You may also opt-out of receiving promotional emails from Digital Holder.</span>

                <span className='title'>Changes to this policy</span>
                <span className='text'>Digital Holder may change this Policy from time to time. Your continued use of our Services after such changes will constitute your acceptance of the revised Policy.</span>

                <span className='title'>Contact</span>
                <span className='text'>If you have any concerns about privacy or grievances at Digital Holder, please contact us at contact@digitalholder.in </span>

                <span className='title'>Our contact details</span>
                <div className='box'>
                    <span className='text'>Digital Holder</span>
                    <span className='text'>Address: [Your Address]</span>
                    <span className='text'>CIN: [Your CIN]</span>
                    <span className='text'>Phone Number: [Your Phone Number]</span>
                    <span className='text'>E-mail: contact@digitalholder.in </span>
                </div>

            </StyledContent>
            <Footer />
        </div>
    )
}

export default Privacy


const StyledContent = styled.div`

margin-top: 100px;
display: flex;
flex-direction: column;
margin-left: 5%;
margin-right: 5%;
margin-bottom: 50px;
gap: 1.5rem;


.text {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.mainTitle {
    font-size: 1.5rem;
    font-weight: 800;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

ul {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

`

