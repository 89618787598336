import React, { useState } from 'react'
import './style.css'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';
import { addFormData } from '../../../api-services/productData';
import { useSelector } from 'react-redux';

const GetDemo = ({ isOpen, onClose }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const productName = useSelector((state) => state.apiRequestType.productName);


    console.log(productName);

    if (!isOpen) {
        return null;
    }



    const handleSubmit = async () => {
        try {

            if (name && email && phoneNumber && phoneNumber.length > 2) {

                const data = {
                    name: name,
                    email: email,
                    contact: phoneNumber,
                    queryType: 'demo',
                    queryFor: productName
                }

                const submitedData = await addFormData(data);

                if (submitedData) {
                    setName('');
                    setEmail('');
                    setPhoneNumber('');
                }

                toast.success("We will contact you soon", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });

                onClose();

            } else {
                toast.warning("Please Fill all details", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: "toastify",
                });
            }

        } catch (e) {
            toast.error(e.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "toastify",
            });
            console.log("Error", e)
        }
    }



    return (
        <div className="modal" onClick={onClose}>
            <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
            >
                <span className="registerModalTitle" style={{ color: 'black' }}>Please enter your details below to Schedule Demo</span>
                <>
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="modalinput"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="modalinput"
                    />
                    <div className="customPhoneInput">
                        <PhoneInput
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            country="in" // Set initial country code to India (+91)
                        />
                    </div>
                    <button className="modalBtn" onClick={handleSubmit}>Schedule Demo</button>
                </>
            </div>
        </div>
    )
}

export default GetDemo

