import Navbar from '../components/commonComponents/Navbar'
import HeroSectionTop from '../components/commonComponents/HeroSectionTop'
import ProductDetails from '../components/productDetailsComponents/ProductDetails'
import ProductServiceTitle from '../components/productDetailsComponents/ProductServiceTitle'
import ProductDemo from '../components/productDetailsComponents/ProductDemo'
import ProductPricing from '../components/productDetailsComponents/ProductPricing'
import ProductFeatures from '../components/productDetailsComponents/ProductFeatures'
import ProductFAQ from '../components/productDetailsComponents/ProductFAQ'
import Footer from '../components/commonComponents/Footer'
import ProductSpec from '../components/productDetailsComponents/ProductSpec'
import Loader from '../components/commonComponents/Loader'
import { useDispatch, useSelector } from 'react-redux'
import ProductDesc from '../components/productDetailsComponents/ProductDesc'
import ProductReview from '../components/productDetailsComponents/ProductReview'
import ProductPlans from '../components/productDetailsComponents/ProductPlans'




const ProductDetail = () => {
  const dispatch = useDispatch();
  const productId = useSelector((state) => state.apiRequestType.productId);
  const { data, status, error } = useSelector((state) => state.products);


  if (status === "loading") {
    return <Loader />;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (status === 'succeeded') {
    var filteredProduct = data.filter(d => d.productId === productId);
  }


  return (
    <div className='productDetailsPage'>
      {status === 'succeeded' &&
        <>
          <Navbar />
          <HeroSectionTop />
          <ProductDetails productId={filteredProduct[0].productId} logo={filteredProduct[0].logo} images={filteredProduct[0].images} name={filteredProduct[0].name} brand={filteredProduct[0].brand} category={filteredProduct[0].category} industry={filteredProduct[0].industry} rating={filteredProduct[0].rating} price={filteredProduct[0].price} desc={filteredProduct[0].desc} reviews={filteredProduct[0].reviews} />
          <ProductServiceTitle />
          <ProductDesc name={filteredProduct[0].name} desc={filteredProduct[0].desc} />
          <ProductPlans plans={filteredProduct[0].plans} productId={filteredProduct[0].productId} />
          <ProductDemo name={filteredProduct[0].name} videoUrl={filteredProduct[0].videoUrl} />
          <ProductPricing price={filteredProduct[0].price} name={filteredProduct[0].name} />
          <ProductFeatures features={filteredProduct[0].features} />
          <ProductSpec />
          <ProductReview data={filteredProduct[0]} />
          <ProductFAQ faq={filteredProduct[0].faq} reviews={filteredProduct[0].reviews} />
          <Footer />
        </>
      }
    </div>
  )
}

export default ProductDetail

