import React, { useEffect } from 'react'
import AdminNavbar from '../components/adminPageComponents/AdminNavbar'
import ProductDetailsForm from '../components/adminPageComponents/ProductDetailsForm'
import { useDispatch } from 'react-redux'
import { setFilterParam, setFilterParamData } from '../reduxSlices/apiRequestTypeSlice'

const AdminPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterParam(''));
    dispatch(setFilterParamData(''));
  }, [])

  return (
    <div className='adminPage'>
      <AdminNavbar />
      <ProductDetailsForm />
    </div>
  )
}

export default AdminPage



