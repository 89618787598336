import productCategoryAgricultureImg from '../assets/productCategoryAgricultureImg.png'
import productCategoryArchitectImg from '../assets/productCategoryArchitectImg.png'
import productCategoryEcommerceImg from '../assets/productCategoryEcommerceImg.png'
import productCategoryAutomobileImg from '../assets/productCategoryAutomobileImg.png'
import healthcareImg from '../assets/healthcareImg.jpg'
import educationImg from '../assets/educationImg.jpg'
import financeImg from '../assets/financeImg.jpg'
import gamingImg from '../assets/gamingImg.jpg'
import foodandbeverageImg from '../assets/foodandbeverageImg.jpg'
import realstateImg from '../assets/realStateImg.jpg'
import retailImg from '../assets/ratailImg.jpg'
import technologyImg from '../assets/technologyImg.jpg'



export const displayReviewText = [
    "I'm amazed by the variety of software available on this marketplace. It's a one-stop-shop for all my software needs!",
    "The user interface is intuitive and easy to navigate. Finding and purchasing software has never been this convenient.",
    "I had a minor issue with my purchase, but the customer support team was quick to assist me. Excellent service!",
    "The prices here are competitive, and I love the frequent discounts they offer. Great value for money.",
    "I'm a developer, and this marketplace makes it incredibly simple to sell my software products. Kudos to the platform!",
    "The reviews and ratings for each software help me make informed decisions. I appreciate the transparency.",
    "I've been a loyal customer for years, and the quality of the software I've purchased here has never disappointed me.",
    "The checkout process is smooth, and I appreciate the secure payment options they provide.",
    "I found a rare software gem on this platform that I couldn't find anywhere else. Truly a hidden treasure trove.",
    "As a business owner, this marketplace has saved me both time and money. Highly recommended for entrepreneurs.",
    "The search filters make it easy to narrow down my options and find exactly what I need. A time-saver for sure.",
    "I had doubts about purchasing software online, but this marketplace changed my perception. Trustworthy and reliable.",
    "The platform's recommendation engine suggests software tailored to my needs. It's like having a personal shopper!",
    "I've recommended this marketplace to all my colleagues. It's become my go-to place for software purchases.",
    "The frequent updates and new arrivals keep me coming back. There's always something exciting to discover.",
];


export const softwareCategories = [
    { category: "HR Software", endpoint: "hr", image: productCategoryAgricultureImg },
    { category: "Accounting Software", endpoint: "accounting", image: financeImg },
    { category: "Billing And Invoicing Software", endpoint: "billing", image: productCategoryArchitectImg },
    { category: "Ecommerce", endpoint: "ecommerce", image: productCategoryEcommerceImg },
    { category: "Automobile", endpoint: "automobile", image: productCategoryAutomobileImg },
    { category: "Healthcare", endpoint: "healthcare", image: healthcareImg },
    { category: "Education", endpoint: "education", image: educationImg },
    { category: "Gaming", endpoint: "gaming", image: gamingImg },
    { category: "Food and Beverage", endpoint: "food-and-beverage", image: foodandbeverageImg },
    { category: "Retail", endpoint: "retail", image: retailImg },
    { category: "Real Estate", endpoint: "real-estate", image: realstateImg },
    { category: "Technology", endpoint: "technology", image: technologyImg },
    // Add more categories as needed
];


export const recommendBtnArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];


