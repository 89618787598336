import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Navbar from '../components/commonComponents/Navbar'
import OrderNavbar from '../components/adminPageComponents/OrderNavbar'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/commonComponents/Loader'
import { getAllOrder } from '../api-services/productData'

const AdminOrders = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const { data, status, error } = useSelector((state) => state.products);
    const [orders, setOrders] = useState();



    useEffect(() => {
        const handleOrders = async () => {
            const data = await getAllOrder();
            setOrders(data.data);
            console.log(data.data);
        }

        if (user) {
            handleOrders();
        }
    }, [user])

    if (status === "loading" || !orders || !user) {
        return <Loader />;
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    const formatDate = (data) => {
        const date = new Date(data);
        const timeZoneOffset = -330;
        date.setMinutes(date.getMinutes() + timeZoneOffset);
        const formattedDateTime = date.toISOString().split('T')[0];

        return formattedDateTime;
    }

    return (
        <StyledDiv>
            <OrderNavbar />

            <div className='orderBox'>
                {orders.length === 0 ? <div>No Orders Found</div> : (orders && status === 'succeeded') &&
                    orders.map((order, index) => (
                        <div className='box'>
                            <img src={data.filter(d => d.productId === order.productId)[0].logo} />
                            <div>
                                <span style={{ fontWeight: '600' }}>{data.filter(d => d.productId === order.productId)[0].name}</span>
                                <span style={{ fontWeight: '600' }}>Price : {order.amount}</span>
                            </div>

                            <div>
                                <span>Date of Order : <b>{formatDate(order.time)}</b></span>
                                <span>Payment ID: <b>{order.paymentId}</b></span>
                            </div>
                        </div>
                    ))
                }
            </div>

        </StyledDiv>
    )
}

export default AdminOrders


const StyledDiv = styled.div`
margin: 0 0;

.orderBox {
    margin: 3rem 10%;
}

.box {
    margin: 2rem 0;
}

`
