import React from 'react'
import FormData from '../components/commonComponents/FormData'
import FormDataNavbar from '../components/adminPageComponents/FormDataNavbar'

const FormDataPage = () => {
    return (
        <div className='formDataPage'>
            <FormDataNavbar/>
            <FormData/>
        </div>
    )
}

export default FormDataPage


