import React from 'react'
import ProductLists from '../components/productsPageComponents/ProductLists'
import Navbar from '../components/commonComponents/Navbar'
import HeroSectionTop from '../components/commonComponents/HeroSectionTop'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/commonComponents/Loader'

const ProductPage = () => {
    const dispatch = useDispatch();
    const { data, status, error } = useSelector((state) => state.products);


    if (status === "loading") {
        return <Loader />;
    }

    if (status === "failed") {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='productPage'>
            {status === 'succeeded' &&
                <>
                    <Navbar />
                    {/* <HeroSectionTop /> */}
                    <ProductLists data={data} />
                </>
            }
        </div>
    )
}

export default ProductPage

