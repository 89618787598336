import React from 'react'
import styled from 'styled-components'
import Navbar from '../Navbar'
import Footer from '../Footer'

const Refund = () => {
    return (
        <div>
            <Navbar />

            <StyledContent>

                <span className='mainTitle'>Cancellation & Refund Policy</span>
                <span className='title'>Last updated on Sep 10th 2023</span>

                <span className='text'>Digitalholder believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</span>

                <li>In case of any Refunds approved by the Digitalholder, it’ll take 3-5 days for the refund to be processed to the end customer.</li>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </StyledContent>

            <Footer />
        </div>
    )
}

export default Refund



const StyledContent = styled.div`

margin-top: 100px;
display: flex;
flex-direction: column;
margin-left: 5%;
margin-right: 5%;
margin-bottom: 50px;
gap: 1rem;


.text {
    font-size: 1rem;
}

.mainTitle {
    font-size: 1.5rem;
    font-weight: 800;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
}

`