import React, { useEffect } from 'react'
import Navbar from '../components/commonComponents/Navbar'
import ProfileContent from '../components/profilePageComponents/ProfileContent'
import { useDispatch } from 'react-redux';
import { setFilterParam, setFilterParamData } from '../reduxSlices/apiRequestTypeSlice';

const ProfilePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFilterParam(''));
        dispatch(setFilterParamData(''));
    }, [])

    return (
        <div className='profilePage'>
            <Navbar />
            <ProfileContent />
        </div>
    )
}

export default ProfilePage

