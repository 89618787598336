import React from 'react'
import './style.css'
import checkmark from '../../../assets/featureAndSpec/checkmark.png'

const ProductFeatures = ({ features }) => {
    return (
        <div className='productFeatures'>
            <span className='productFeaturesTitle'>Product Features</span>
            <div className='productFeaturesContainer'>
                {features.map((product, index) => (
                    <div key={index} className='productFeaturesContent'>
                        <img src={checkmark} className='logo' alt='checkIcon'/>
                        <span className='title'>{product}</span>
                        {/* <span className='desc'>{product.desc}</span> */}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProductFeatures



