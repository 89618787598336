import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { addOrder, payOrder, payStatus } from './api-services/productData';
import { useNavigate } from 'react-router';
import emailjs from '@emailjs/browser';

function PhonePePay({ productId, productName, userId, amount, name, email }) {
    const navigate = useNavigate();

    const [url, setUrl] = useState(null);
    const [merchantId, setMerchantId] = useState(null);
    const [merchantTransactionId, setMerchantTransactionId] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [transId, setTransID] = useState('');


    // useEffect(() => {
    //     const paymentStatus = async () => {
    //         const paid = await payStatus(merchantId, merchantTransactionId);
    //         if (paid) {
    //             setPaymentStatus(paid.data.code)
    //             setTransID(paid.data.data.transactionId)
    //             console.log(paid);
    //         }
    //     }

    //     if (merchantId && merchantTransactionId) {
    //         paymentStatus();
    //     }
    // }, [merchantId, merchantTransactionId]);

    // useEffect(() => {

    //     const putOrder = async () => {
    //         const orderData = {
    //             productId: productId,
    //             userId: userId,
    //             amount: amount,
    //             paymentId: transId,
    //         }
    //         const order = await addOrder(orderData);
    //     }

    //     const handleOrderEmail = (transactID) => {

    //         const serviceId = 'service_ix474zx';
    //         const templateId = 'template_fwbgdyl';
    //         const publicKey = 'FNrwU6YMel3pMRyiy';


    //         const templateParams = {
    //             from_name: name,
    //             from_email: email,
    //             to_name: 'Digitalholder Team',
    //             message: `Order placed by ${name} for ${productName} with payment id ${transactID} and amount ${(((amount * 118) / 100)).toFixed(2)}, please proceed with further process by contacting customer at ${email}`,
    //         }

    //         // Send the email using EmailJS
    //         emailjs.send(serviceId, templateId, templateParams, publicKey)
    //             .then((response) => {
    //                 console.log('Order Placed and email sent to the team to proceed with further process', response);
    //             })
    //             .catch((error) => {
    //                 console.error('Facing some error:', error);
    //             });
    //     }

    //     if (paymentStatus && paymentStatus === 'PAYMENT_SUCCESS') {
    //         handleOrderEmail(transId);
    //         putOrder();
    //     }


    // }, [paymentStatus]);


    useEffect(() => {
        if (url) {
            // Redirect to the payment page
            window.location.href = url;
        }
    }, [url]);


    const initiatePhonePePayment = async () => {
        try {

            const data = {
                amount: (((amount * 118) / 100)).toFixed(2),
                contact: '9995999555',
                productId: productId,
                userId: userId,
                email: email,
                name: name,
            }

            const order = await payOrder(data);

            if (order) {
                setUrl(order.data.instrumentResponse.redirectInfo.url);
                setMerchantId(order.data.merchantId);
                setMerchantTransactionId(order.data.merchantTransactionId);

                console.log(order.data);
            } else {
                console.error('URL not found in API response');
            }
        } catch (error) {
            console.error('API call failed:', error);
        }
    };




    return (
        <StyledDiv>
            <button onClick={initiatePhonePePayment}>Pay &#x20B9; {(((amount * 118) / 100)).toFixed(2)}</button>
        </StyledDiv>
    );
}

export default PhonePePay;

const StyledDiv = styled.div`
margin-top: 2rem;
margin-bottom: 3rem;
display: flex;
justify-content: center;
align-items: center;

button {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #000080;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

`
