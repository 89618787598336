import React from 'react'
import './style.css'
import writeReviewImg from '../../../assets/writeReviewImg.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { setProductId } from '../../../reduxSlices/apiRequestTypeSlice'

const WriteReview = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const addReview = () => {
        dispatch(setProductId(data[0].productId));
        navigate(`/addreview/${data[0].productId}`);
    }


    return (
        <div className='writeReview'>
            <img src={writeReviewImg} className='writeReviewLeft' alt='writeReviewImg' />
            <div className='writeReviewRight'>
                <span>We’d love to hear your feedback.</span>
                <br />
                <span>Review your Software.</span>
                <button onClick={() => addReview()}>Write Review</button>
            </div>
        </div>
    )
}

export default WriteReview


